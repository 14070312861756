import React from 'react';
import Navigation from './ResultNavigation';
import ResultDashboard from './ResultDashboard';
import ResultBanner from './ResultBanner';
import SleepApneaResults from './SleepApneaResults';
import ResultFooter from './ResultsFooter';

function ResultPage() {
    return (
        <div>
            <Navigation/>
            <ResultDashboard/>
            <SleepApneaResults/>
            <ResultFooter/>
        </div>
    );
}

export default ResultPage;