import React from 'react';
import './ResultBanner.css';
import resultBanner from '../../images/Sleep-Challenge-Hero-Banner.jpg';
import Icon from "react-native-vector-icons/FontAwesome5"; 

function ResultBanner() {
    return(
        <div className="result__banner__outer__col" style={{backgroundImage: `url(${resultBanner})`}}>
            <div className="container">
                <div className="result__banner__inner__col">
                    <div className="result__banner__subheading">
                        <p>Good sleep is essential to good health</p>
                    </div>
                    <div className="result__banner__heading">
                        <h2>Awaken your best with the 14 night sleep challenge</h2>
                    </div>
                    <div className="result__banner__content">
                        <p>Take part in this fun sleep challenge where you will learn some great tips and habits to help you improve your nightly routine and get a more restful sleep. Ready to take the challenge?</p>
                    </div>
                    <div className="result__banner__btn">
                        <a href="#" className="button pink__btn">Take the sleep challenge <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultBanner;