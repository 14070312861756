import React from 'react';
import './BannerNew.css';
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5"; 
import bannerLogo from '../../images/resmed-icon-white.svg';
import NewbannerBackground from '../../images/sleep-apnea-sleep-background-9D2872.gif';
import bannerquizimage from '../../images/sleep-apnea-sleep-assessment-illustration-health-professional.png';
import clockimage from '../../images/clock.svg';
// import ReactGA from 'react-ga';
// import gtag, { install } from 'ga-gtag';

// install('GTM-W2HVGBF');

export default function BannerNew() {

    // var clicks = 0;
    // var btnName;

    function scrollDownOne(event1) {
        event1.preventDefault();

        // clicks += 1;
        // btnName = document.getElementById("bannerBtn").innerHTML;

        $('html,body').animate({ 
            scrollTop: $(".form_wrapper").offset().top},
        'slow');
    }

    const { t } = useTranslation();
    return ( 
    <div className = "banner_outer_wrp" style={{backgroundImage: `url(${NewbannerBackground})`}}>
        <div className = "container">
            <div className = "banner_logo">
                <img src={bannerLogo}/>
            </div>
            <div className = "banner_inner_wrp">
                <div className="banner_left_text_section">
                    <h1>{t("newBannerheader")}</h1>
                    <p>{t("newBannersubpara")}</p>
                    <div className="banner_button_wrp">
                        <div className="Banner_button_col">
                            <a href="#" className="button banner-white-btn" onClick={scrollDownOne}> <span id="bannerBtn">{t("newBannerbutton")}</span>
                            <Icon name="arrow-right" size={12} color="#9D2872" /></a>
                        </div>
                        <div className="quiz-time">
                        <img src={clockimage}/><p>{t("quiz_time")}</p>  
                        </div>
                    </div>
                </div>
                <div className = "banner_right-Image">
                    <div className="banner_right_image_section">
                        <img src={bannerquizimage}/>
                    </div>
                </div>
            </div>
            <div className="noteTextCol">
                <p>{t("quiz_note")}</p>
            </div>
        </div>
    </div>
    );

}