import React from 'react';
import Footer from './Footer';
import BannerNew from './BannerNew';
import Newformsec from './Newform_section';
import BlackheaderNew from './Blackheader';

function LandingPage() {
    return ( < div className = "landing__row__wrapper" >
        <BlackheaderNew/>
        <BannerNew/>
        <Newformsec/>
        <Footer/>
        </div>
    );
}

export default LandingPage;