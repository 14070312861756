import React from 'react';
import './App.css';
import LandingPage from './src/components/Quote/LandingPage';
import Quiz from './src/components/Quote/Quiz';
import ResultPage from './src/components/Quote/ResultPage';
import "./src/components/Quote/i18n";
import $ from 'jquery';

import Favicon from 'react-favicon';
import FaviconLogo from './src/images/ResMed-Next-Favicon.png';

import { BrowserRouter as Router, Route, Link, NavLink, Switch} from 'react-router-dom';

function App() {
  
  const scriptCode = "<script>!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '1808340955974542'); fbq('track', 'PageView');</script>";
  $('body').append(scriptCode);

  const newCode = "<noscript> <img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=1808340955974542&ev=PageView&noscript=1'/></noscript>";
  $('body').append(newCode);
  
  return (
    <div>
      <Favicon url={FaviconLogo} />
      <Router>
         <div className="app">
           <Switch>
             <Route exact path="/" component={LandingPage} />
             <Route path="/quiz" component={Quiz} />
             <Route path="/result" component={ResultPage}/>
           </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
