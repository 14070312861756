import React, { useState } from 'react';
import $ from 'jquery';
import './SleepApneaResults.css';
import IconOne from '../../images/Steps-icon-one.svg'
import IconTwo from '../../images/Steps-icon-two.svg'
import Icon from "react-native-vector-icons/FontAwesome5"; 
import { useTranslation } from "react-i18next";
import HealthySleepWebinar from './HealthySleepWebinar';

function SleepApneaResults() {
    $(document).ready(function() {
        //$('.sleep_steps__content__col').hide();
        $('.sleep_steps__icon svg.sl__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.al__apnea').slideDown();
            } else {
                $('.al__apnea').slideUp();
            }
        });       
        $('.sleep_steps__icon svg.isno__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.isno__apnea').slideDown();
            } else {
                $('.isno__apnea').slideUp();
            }
        });       
        $('.sleep_steps__icon svg.partner__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.partner__apnea').slideDown();
            } else {
                $('.partner__apnea').slideUp();
            }
        });        
        $('.sleep_steps__icon svg.nose__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.nose__apnea').slideDown();
            } else {
                $('.nose__apnea').slideUp();
            }
        });        
        $('.sleep_steps__icon svg.mouth__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.mouth__apnea').slideDown();
            } else {
                $('.mouth__apnea').slideUp();
            }
        });       
        $('.sleep_steps__icon svg.health__ap').click(function () {
            if($(this).parents('.sleep__steps__outer__col').hasClass('not__open')) {
                $('.health__apnea').slideDown();
            } else {
                $('.health__apnea').slideUp();
            }
        });  
    });

    const [isActive, setActive] = useState("false");
    const [isnoActive, setisnoActive] = useState("false");
    const [partnerActive, setpartnerActive] = useState("false");
    const [noseActive, setnoseActive] = useState("false");
    const [mouthActive, setmouthActive] = useState("false");
    const [healthActive, sethealthActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
    };

    const isnohandleToggle = () => {
        setisnoActive(!isnoActive);
    };

    const partnerhandleToggle = () => {
        setpartnerActive(!partnerActive);
    };

    const nosehandleToggle = () => {
        setnoseActive(!noseActive);
    };

    const mouthhandleToggle = () => {
        setmouthActive(!mouthActive);
    };

    const healthhandleToggle = () => {
        sethealthActive(!healthActive);
    };

    const insomniarisk = localStorage.getItem('insomniarisk');
    const snoreTypenew = localStorage.getItem('snoreType');
    const osaRisk = localStorage.getItem('osaRisk');

    const { t } = useTranslation();

    return(
        <div className="sleep__results__outer__col">
            <div className="container">
                {osaRisk && <div className="sleep__results__inner__col" id="sleep-apnea">
                    <div className="sleep__results__content">
                        <h3>{t("sleep_apneaone")}</h3>
                        <h2>{t("more_detail")}</h2>
                        {osaRisk == "highrisk" && <p>{t("sleep_apnea_para")}</p>}
                        {osaRisk == "lowrisk" && <p>{t("sleep_apnea_para_low")}</p>}
                        {osaRisk == "atrisk" && <p>{t("sleep_apnea_para_at")}</p>}
                        <p>{t("sleep_apnea_paratwo")}<sup>1</sup> {t("sleep_apnea_paratwosec")}<sup>2</sup></p>
                        <ul>
                            <li>1 {t("sleep_apnea_listone")}</li>
                            <li>2 {t("sleep_apnea_listtwo")}</li>
                        </ul>
                    </div>
                    <div className="sleep__results__img">
                        <svg viewBox="0 0 36 36" className="circular-chart -sleepApnea">
                            <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path className={`circle ${osaRisk}`} strokeDasharray="0, 100" d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                        </svg>
                        {osaRisk == "highrisk" && <div className="dashboard__risk"><span><strong>High</strong> {t("high_riskone")}</span></div>}
                        {osaRisk == "lowrisk" && <div className="dashboard__risk"><span><strong>Low</strong> {t("low_riskone")}</span></div>}
                        {osaRisk == "atrisk" && <div className="dashboard__risk"><span><strong>Medium</strong> {t("at_riskone")}</span></div>}
                    </div>
                </div>}
                {osaRisk && <div className={"sleep__steps__outer__col " + (isActive ? "is__open" : "not__open")}>
                    <div className="container">
                        <div className="sleep_steps__inner__col">
                            <div className="sleep_steps__header__col">
                                <h2>{t("sleep_apnea_next")}</h2>
                                <div className="sleep_steps__icon">
                                    <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="sl__ap" onClick={handleToggle}>
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                            <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                            <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="sleep_steps__content__col al__apnea">
                                <div className="sleep_steps__content__wrapper">
                                    <div className="sleep_steps__inner__wrapper">
                                        <div className="sleep__steps__icon">
                                            <img src={IconOne} />
                                        </div>
                                        <div className="sleep__steps__heading">
                                            <h2>{t("what_is_sleep")}?</h2>
                                        </div>
                                        <div className="sleep__steps__content">
                                            <p>{t("what_is_sleep_para")}</p>
                                        </div>
                                        <div className="sleep__steps__btn">
                                            <a href="https://www.resmed.sg/sleep/diseases-conditions/what-is-sleep-apnea?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className='gtm-click' data-event-label="Sleep Apnea Learn More">{t("learn_more")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="sleep_steps__content__wrapper">
                                    <div className="sleep_steps_inner__wrapper">
                                        <div className="sleep__steps__icon">
                                            <img src={IconTwo} />
                                        </div>
                                        <div className="sleep__steps__heading">
                                            <h2>{t("what_should_next")}</h2>
                                        </div>
                                        <div className="sleep__steps__content">
                                            {osaRisk == "highrisk" && <p>{t("what_should_text_high")}</p>}
                                            {osaRisk == "lowrisk" && <p>{t("what_should_text_low")}</p>}
                                            {osaRisk == "atrisk" && <p>{t("what_should_text_at")}</p>}
                                        </div>
                                        <div className="sleep__steps__btn">
                                            {/* {osaRisk == "highrisk" && <a href="https://www.resmed.sg/contact?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className='gtm-click' data-event-label="High Risk Find a dealer">{t("home_sleep_test_high")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>}
                                            {osaRisk == "highrisk" && <a href="https://www.resmed.sg/sleep/screening-diagnosis/sleep-apnea-diagnosis-treatment-journey?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className="white-bg gtm-click" data-event-label="High Risk About Treatment">{t("treatment_options")} <Icon name="arrow-right" size={12} color="#9d2872" /></a>}
                                            {osaRisk == "atrisk" && <a href="https://www.resmed.sg/contact?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className='gtm-click' data-event-label="At Risk Find a dealer">{t("home_sleep_test_high")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>}
                                            {osaRisk == "atrisk" && <a href="https://www.resmed.sg/sleep/screening-diagnosis/sleep-apnea-diagnosis-treatment-journey?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className="white-bg gtm-click" data-event-label="At Risk About Treatment">{t("treatment_options")} <Icon name="arrow-right" size={12} color="#9d2872" /></a>}
                                            {osaRisk == "lowrisk" && <a href="https://www.resmed.sg/contact?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className='gtm-click' data-event-label="Low Risk Sleep Score">{t("sleep_score")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>}
                                            {osaRisk == "lowrisk" && <a href="https://www.resmed.sg/sleep/screening-diagnosis/sleep-apnea-diagnosis-treatment-journey?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className="white-bg gtm-click" data-event-label="Low Risk About Treatment">{t("treatment_options")} <Icon name="arrow-right" size={12} color="#9d2872" /></a>} */}
                                            <a href="https://www.resmed.sg/contact?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank" className='gtm-click' data-event-label="Sleep Apnea Contact Us">{t("contactbtn")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* {insomniarisk && <div className="sleep__results__inner__col" id="Insomnia">
                    <div className="sleep__results__content">
                        <h3>{t("insomnia")}</h3>
                        <h2>{t("insomnia_detail")}</h2>
                        {insomniarisk == "highrisk" && <p>{t("insomnia_para")}</p>}
                        {insomniarisk == "highrisk" && <p>{t("insomnia_paraone")}</p> }
                        {insomniarisk == "moderaterisk" && <p>{t("insomnia_para_mod")}</p>}
                        {insomniarisk == "moderaterisk" && <p>{t("insomnia_para_modone")}</p> }
                        {insomniarisk == "atrisk" && <p>{t("insomnia_para_at")}</p>}
                        {insomniarisk == "atrisk" && <p>{t("insomnia_para_atone")}</p> }
                        {insomniarisk == "lowrisk" && <p>{t("insomnia_para_low")}</p>}
                    </div>
                    <div className="sleep__results__img">
                        <svg viewBox="0 0 36 36" className="circular-chart -insomnia">
                            <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path className={`circle ${insomniarisk}`} strokeDasharray="0, 100" d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                        </svg>
                        {insomniarisk == "highrisk" && <div className="dashboard__risk">{t("high_risk_of_insomnia")}</div>}
                        {insomniarisk == "lowrisk" && <div className="dashboard__risk">{t("low_risk_of_insomnia")}</div>}
                        {insomniarisk == "atrisk" && <div className="dashboard__risk">{t("at_risk_of_insomnia")}</div>}
                        {insomniarisk == "moderaterisk" && <div className="dashboard__risk">{t("mod_risk_of_insomnia")}</div>}
                    </div>
                </div>} */}
                {/* {insomniarisk && <div className={"sleep__steps__outer__col " + (isnoActive ? "not__open" : "is__open")}>
                    <div className="container">
                        <div className="sleep_steps__inner__col">
                            <div className="sleep_steps__header__col">
                                <h2>{t("insomnia_next")}</h2>
                                <div className="sleep_steps__icon">
                                    <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="isno__ap" onClick={isnohandleToggle}>
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                            <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                            <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="sleep_steps__content__col isno__apnea">
                                <div className="sleep_steps__content__wrapper">
                                    <div className="sleep_steps__inner__wrapper">
                                        <div className="sleep__steps__icon">
                                            <img src={IconOne} />
                                        </div>
                                        <div className="sleep__steps__heading">
                                            <h2>{t("what_is_insomnia")}?</h2>
                                        </div>
                                        <div className="sleep__steps__content">
                                            <p>{t("what_is_insomniapara")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sleep_steps__content__wrapper">
                                    <div className="sleep_steps_inner__wrapper">
                                        <div className="sleep__steps__icon">
                                            <img src={IconTwo} />
                                        </div>
                                        <div className="sleep__steps__heading">
                                            <h2>{t("what_should_next")}</h2>
                                        </div>
                                        <div className="sleep__steps__content">
                                            {insomniarisk == "highrisk" && <p>{t("what_is_insomnianext")}</p> }
                                            {insomniarisk == "lowrisk" && <p>{t("what_is_insomniaothers")}</p> }
                                            {insomniarisk == "lowrisk" && <p>{t("what_is_insomniaotherstwo")}</p> }
                                            {insomniarisk == "atrisk" && <p>{t("what_is_insomniaothers")}</p> }
                                            {insomniarisk == "atrisk" && <p>{t("what_is_insomniaotherstwo")}</p> }
                                            {insomniarisk == "moderaterisk" && <p>{t("what_is_insomniaothers")}</p> }
                                            {insomniarisk == "moderaterisk" && <p>{t("what_is_insomniaotherstwo")}</p> }
                                        </div>
                                        <div className="sleep__steps__btn">
                                            <a href="https://shop.resmed.sg/collections/insomnia?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("insomnia_products")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} */}
                {/* <div id="Snoring">
                    {snoreTypenew == "partner" && <div className="sleep__results__inner__col">
                        <div className="sleep__results__content">
                            <h3>{t("snoring")}</h3>
                            <h2>{t("snoring_detail")}</h2>
                            <p>{t("partner_who_snores")}</p>
                        </div>
                        <div className="sleep__results__img">
                            <svg viewBox="0 0 36 36" className="circular-chart -snoring">
                                <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                <path className="circle highrisk" strokeDasharray="0, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            </svg>
                            <div className="dashboard__risk">{t("partner_snoring")}</div>
                        </div>
                    </div>} 
                    {snoreTypenew == "partner" && <div className={"sleep__steps__outer__col " + (partnerActive ? "not__open" : "is__open")}>
                        <div className="container">
                            <div className="sleep_steps__inner__col">
                                <div className="sleep_steps__header__col accordion">
                                    <h2>{t("your_snoring_next")}</h2>
                                    <div className="sleep_steps__icon accordion">
                                        <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="partner__ap" onClick={partnerhandleToggle}>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                                <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                                <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="sleep_steps__content__col partner__apnea">
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps__inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconOne} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_is_snoring")}?</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("what_snore_para")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://www.resmed.sg/sleep/diseases-conditions/what-causes-snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("learn_more")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps_inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconTwo} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_should_next")}</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("worried_para")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://shop.resmed.sg/collections/snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("snoring_products")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {snoreTypenew == "nose" && <div className="sleep__results__inner__col">
                        <div className="sleep__results__content">
                            <h3>{t("nose_snorer")}</h3>
                            <h2>{t("snoring_detail__nose")}</h2>
                            <p>{t("suggest_snore")}</p>
                        </div>
                        <div className="sleep__results__img">
                            <svg viewBox="0 0 36 36" className="circular-chart -snoring">
                                <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                <path className="circle highrisk" strokeDasharray="0, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            </svg>
                            <div className="dashboard__risk">{t("nose_snorer")}</div>
                        </div>
                    </div>}
                    {snoreTypenew == "nose" && <div className={"sleep__steps__outer__col " + (noseActive ? "not__open" : "is__open")}>
                        <div className="container">
                            <div className="sleep_steps__inner__col">
                                <div className="sleep_steps__header__col">
                                    <h2>{t("nose_snore_next")}</h2>
                                    <div className="sleep_steps__icon">
                                        <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="nose__ap" onClick={nosehandleToggle}>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                                <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                                <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="sleep_steps__content__col nose__apnea">
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps__inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconOne} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_is_snoring")}?</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("nose_para")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://www.resmed.sg/sleep/diseases-conditions/what-causes-snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("learn_more")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps_inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconTwo} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_should_next")}</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("nose_para_one")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://shop.resmed.sg/collections/snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("snoring_products")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {snoreTypenew == "mouth" && <div className="sleep__results__inner__col">
                        <div className="sleep__results__content">
                            <h3>{t("mouth_snorer")}</h3>
                            <h2>{t("snoring_detail_mouth")}</h2>
                            <p>{t("mouth_snore_para")}</p>
                        </div>
                        <div className="sleep__results__img">
                            <svg viewBox="0 0 36 36" className="circular-chart -snoring">
                                <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                <path className="circle highrisk" strokeDasharray="0, 100" d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            </svg>
                            <div className="dashboard__risk">{t("mouth_snorer")}</div>
                        </div>
                    </div>}
                    {snoreTypenew == "mouth" && <div className={"sleep__steps__outer__col " + (mouthActive ? "not__open" : "is__open")}>
                        <div className="container">
                            <div className="sleep_steps__inner__col">
                                <div className="sleep_steps__header__col accordion">
                                    <h2>{t("mouth_snorer_next")}</h2>
                                    <div className="sleep_steps__icon">
                                        <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="mouth__ap" onClick={mouthhandleToggle}>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                                <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                                <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div className="sleep_steps__content__col mouth__apnea">
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps__inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconOne} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_is_snoring")}?</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("mouth_snorer_detail")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://www.resmed.sg/sleep/diseases-conditions/what-causes-snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("learn_more")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sleep_steps__content__wrapper">
                                        <div className="sleep_steps_inner__wrapper">
                                            <div className="sleep__steps__icon">
                                                <img src={IconTwo} />
                                            </div>
                                            <div className="sleep__steps__heading">
                                                <h2>{t("what_should_next")}</h2>
                                            </div>
                                            <div className="sleep__steps__content">
                                                <p>{t("mouth_snorer_next_para")}</p>
                                            </div>
                                            <div className="sleep__steps__btn">
                                                <a href="https://shop.resmed.sg/collections/snoring?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("snoring_products")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div> */}
                   {/* <div className="sleep__results__inner__col" id="sleep-health">
                       <div className="sleep__results__content">
                           <h3>{t("sleep_health")}</h3>
                           <h2>{t("results_more_detail")}</h2>
                           <p>{t("sleep_healthpara")}</p>
                       </div>
                       <div className="sleep__results__img">
                           <svg viewBox="0 0 36 36" className="circular-chart -snoring">
                               <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                               <path className="circle highrisk" strokeDasharray="0, 100" d="M18 2.0845
                                       a 15.9155 15.9155 0 0 1 0 31.831
                                       a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                           </svg>
                           <div className="dashboard__risk">{t("sleep_healthone")}</div>
                       </div>
                   </div> */}
                   {/* <div className={"sleep__steps__outer__col last__section " + (healthActive ? "not__open" : "is__open")}>
                       <div className="container">
                           <div className="sleep_steps__inner__col">
                               <div className="sleep_steps__header__col">
                                   <h2>{t("sleep_health_next")}</h2>
                                   <div className="sleep_steps__icon">
                                       <svg width="64px" height="20px" viewBox="0 0 64 20" version="1.1" className="health__ap" onClick={healthhandleToggle}>
                                           <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                               <g id="Results-Page,-Sticky-Nav---Desktop" transform="translate(-1225.000000, -2045.000000)" stroke="#979797" strokeWidth="2">
                                               <polyline id="Path-Copy-3" transform="translate(1256.751953, 2046.751953) rotate(-45.000000) translate(-1256.751953, -2046.751953) " points="1235 2025 1244.85588 2058.64802 1278.50391 2068.50391"></polyline>
                                               </g>
                                           </g>
                                       </svg>
                                   </div>
                               </div>
                               <div className="sleep_steps__content__col health__apnea">
                                   <div className="sleep_steps__content__wrapper">
                                       <div className="sleep_steps__inner__wrapper">
                                           <div className="sleep__steps__icon">
                                               <img src={IconOne} />
                                           </div>
                                           <div className="sleep__steps__heading">
                                               <h2>{t("what_is_sleep_health")}?</h2>
                                           </div>
                                           <div className="sleep__steps__content">
                                               <p>{t("sleep_health_para")}</p>
                                           </div>
                                           <div className="sleep__steps__btn">
                                               <a href="https://shop.resmed.sg/blogs/sleep-articles?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("sleep_health_btn")} <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="sleep_steps__content__wrapper">
                                       <div className="sleep_steps_inner__wrapper">
                                           <div className="sleep__steps__icon">
                                               <img src={IconTwo} />
                                           </div>
                                           <div className="sleep__steps__heading">
                                               <h2>{t("the_next_steps")}</h2>
                                           </div>
                                           <div className="sleep__steps__content">
                                               <p>{t("sleep_helps_you")}</p>
                                           </div>
                                           <div className="sleep__steps__btn">
                                               <a href="https://shop.resmed.sg/collections/sleep-health?utm_id=OnlineSleepTest&utm_source=result&utm_medium=web" target="_blank">{t("sleep_health_products_last")}<Icon name="arrow-right" size={12} color="#ffffff" /></a>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                          </div>
                     </div>
                 </div> */}
            </div>
        </div>
    );
}

export default SleepApneaResults;