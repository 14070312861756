import React from 'react';
import $ from 'jquery';
import HeaderLogo from '../../images/resmed-icon-white.svg';
import './Navigation.css';
import { useTranslation } from "react-i18next";

function ReactNavigation() {
    $(document).ready(function() {
        $('.header__menu__col ul a.dashboard').click(function() {
            if($("#dashboard").is(':visible')) {
                $('html, body').animate({
                    scrollTop: $("#dashboard").offset().top
                }, 100);
            }
        });

        $('.header__menu__col ul a.sleep_apnea').click(function() {
            if($("#sleep-apnea").is(':visible')) {
                $('html, body').animate({
                    scrollTop: $("#sleep-apnea").offset().top
                }, 100);
            }
        });

        $('.header__menu__col ul a.Insomnia').click(function() {
            if($("#Insomnia").is(':visible')) {
                $('html, body').animate({
                    scrollTop: $("#Insomnia").offset().top
                }, 100);
            }
        });

        $('.header__menu__col ul a.Snoring').click(function() {
            if($("#Snoring").is(':visible')) {
                $('html, body').animate({
                    scrollTop: $("#Snoring").offset().top
                }, 100);
            }
        });

        $('.header__menu__col ul a.sleep_health').click(function() {
            if($("#sleep-health").is(':visible')) {
                $('html, body').animate({
                    scrollTop: $("#sleep-health").offset().top
                }, 100);
            }
        });
    });

    function LoadFunc() { 
        document.body.classList.remove("active__loader");
    }

    window.onload = LoadFunc;

    const { t } = useTranslation();

    return (
        <div className="header__outer__col">
            <div className="container">
                <div className="header__inner__col">
                    <div className="header__logo__col">
                        <img src={HeaderLogo} />
                    </div>
                    <div className="header__menu__col">
                        <ul>
                            <li><a data-href="#dashboard" className="dashboard">{t("dashboard")}</a></li>
                            <li><a data-href="#sleep-apnea" className="sleep_apnea">{t("sleep_apnea")}</a></li>
                            <li><a data-href="#Insomnia" className="Insomnia">{t("insomnia")}</a></li>
                            <li><a data-href="#Snoring" className="Snoring">{t("snoring")}</a></li>
                            <li><a data-href="#sleep-health" className="sleep_health">{t("sleep_health")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReactNavigation;