import React from 'react';
import './Footer.css';
import FooterLogo from '../../images/resmed-icon-white.svg';
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    return (
        <div className="footer__outer__wrapper">
            <div className="container">
                <div className="footer__inner__wrapper">
                    <div className="footer__logo__wrapper">
                        <img src={FooterLogo} />
                    </div>
                    <div className="footer__content__wrapper">
                        <div className="footer__copy__wrapper">
                            <p>{t("copyright")}</p>
                        </div>
                        <div className="footer__menu__wrapper">
                            <ul>
                                <li><a href="#">{t("termsandconditions")}</a></li>
                                <li><a href="#">{t("policy")}</a></li>
                                <li><a href="#">{t("contactus")}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}