import React from 'react';
import './Navigation.css';
import Icon from "react-native-vector-icons/FontAwesome5"; 
import { useTranslation } from "react-i18next";

function ResultDashboard() {
    const name_val = localStorage.getItem('myData');
    const osaRisk = localStorage.getItem('osaRisk');
    const snoretype = localStorage.getItem('snoreType');

    function loladContent() {   
        document.body.classList.add("remove_lng");
    }

    window.onload = loladContent;

    const { t } = useTranslation();
    return (
        <div className="dashboard__outer__col" id="dashboard">
            <div className="container">
                <div className="dashboard__inner__col">
                    <div className="dashboard__heading">
                        <h2>{t("hi")} {name_val} {t("here_result")} {osaRisk == "highrisk" && <span><strong>High</strong></span>} {osaRisk == "lowrisk" && <span><strong>Low</strong></span>} {osaRisk == "atrisk" && <span><strong>Medium</strong></span>} {t("here_result_sec")} <span><strong>{t("sleep_apneaone")}</strong></span>.</h2>
                    </div>
                    <div className="dashboard__content">
                        {osaRisk && <div className="dashboard__content__inner">
                            <div className="dashboard__content__svg">
                                <svg viewBox="0 0 36 36" className="circular-chart -sleepApnea">
                                    <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <path className={`circle ${osaRisk}`} strokeDasharray="0, 100" d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                </svg>
                                {osaRisk == "highrisk" && <div className="dashboard__risk"><span><strong>High</strong> {t("high_risk")}</span></div>}
                                {osaRisk == "lowrisk" && <div className="dashboard__risk"><span><strong>Low</strong> {t("low_risk")}</span></div>}
                                {osaRisk == "atrisk" && <div className="dashboard__risk"><span><strong>Medium</strong> {t("at_risk")}</span></div>}
                            </div>
                            {/* <div className="dashboard__risk__btn">
                                <a href="#">{t("see_recommendation")}
                                <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                            </div> */}
                        </div>}
                        {/* {snoretype == "partner" && <div className="dashboard__content__inner">
                            <div className="dashboard__content__svg">
                                <svg viewBox="0 0 36 36" className="circular-chart -snoring">
                                    <path className="circle-bg" strokeDasharray="80, 100" d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <path className="circle highrisk" strokeDasharray="0, 100" d="M18 2.0845
                                            a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                </svg>
                                <div className="dashboard__risk">{t("partner_snoring")}</div>
                            </div>
                            <div className="dashboard__risk__btn">
                                <a href="#">{t("see_recommendation")}
                                <Icon name="arrow-right" size={12} color="#ffffff" /></a>
                            </div>
                        </div>} */}
                    </div>
                </div>
            </div>
            <div className="dashboardNxtCol">
                <div className="container">
                    <div className="dashboardNxtInner">
                        <h3>{t("dash_header")}</h3>
                        <p>{t("dash_content")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultDashboard;