import React from 'react';
import ResultsLogo from '../../images/resmed-icon-white.svg';
import './ResultFooter.css';
import { useTranslation } from "react-i18next";

function ResultFooter() {
    const { t } = useTranslation();
    return(
        <div className="result__footer__outer__col">
            <div className="container">
                <div className="result__footer__inner__col">
                    <a href="#">
                        <img src={ResultsLogo} />
                        <span>{t("return_resmed")}</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ResultFooter;