import React from 'react';
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5";
import './Blackheader.css';



export default function BlackheaderNew() {

    const { t } = useTranslation();
    return ( 
     <div className = "blackHeader_outer_wrp" >
        <div className = "container" >
        <div className = "blacHeader_inner-wrp" >
        <div className = "blackheader_left_section" >
        <a href="http://resmed.sg/" target="_blank"className="button black_banner_btn"><Icon name="arrow-right" size={12} color="#fff" />
        ResMed.sg
            </a>
        </div> 
        < div className = "black_header_rightsection" >

        </div>
    </div>
  </div> 
 </div>

    );
}