import React, { useState } from 'react';
import $ from 'jquery';
import './Quiz.css';
import Footer from './Footer';
import QuizLogo from '../../images/resmed-icon-white.svg';
import QuizContentIcon from '../../images/Quiz-image-one.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';
import { useTranslation } from "react-i18next";
import Icon from "react-native-vector-icons/FontAwesome5";

import GenderIcon from '../../images/01_Gender.png';
import BornIcon from '../../images/01-1_Birthdays.png';
import HeightIcon from '../../images/2_Height.png';
import WeightIcon from '../../images/3_Scale.png';
import NotBreathIcon from '../../images/4_NotBreathingWhileAsleep.png';
import SleepyNextDay from '../../images/5_SleepyNextDay.png';
import NearSnorer from '../../images/6_NearSnorer.png';
import ToldToSnore from '../../images/7_ToldToSnore.png';
import OutOfBreath from '../../images/8_OutOfBreath.png';
import headache from '../../images/9_headache.png';
import pain from '../../images/10_pain.png';
import doctor from '../../images/11_doctor.png';
import longtreatment from '../../images/12_longtreatment.png';
import SleepApnea from '../../images/13_SleepApnea.png';
import whatcaused from '../../images/14_what-caused.png';
import Partner from '../../images/15_Partner.png';
import SleepExpression from '../../images/16_SleepExpression.png';
import clock from '../../images/17_clock.png';
import a_pain from '../../images/18_pain.png';
import cantsleep from '../../images/19_cant-sleep.png';
import sun from '../../images/19-02_Sun.png';
import sleepy from '../../images/20_sleepy.png';
import anxious from '../../images/20-1_anxious.png';
import loadIcon from '../../images/loading_icon.gif';

import { useHistory } from 'react-router-dom';

import CryptoJS from 'crypto-js';
import { REACT_APP_PHRASE, REACT_APP_API, REACT_APP_API_URL } from "@env";

const quizBox = {
    backgroundImage: `url(${loadIcon})`,
}

const quizBox1 = {
    opacity: '0'
}

function Quiz(props) {
    const [enteredBornVal, setEnteredBornVal] = useState("");
    const [enteredWeightVal, setEnteredWeightVal] = useState("");
    const [enteredTallVal, setEnteredTallVal] = useState("");

    let settings = {
        fade: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        draggable: false,
        swipe: false,
        touchMove: false,
        accessibility: false
    }

    /********************************** Loading Functions ****************************************/

    function slideCounter() {   
        document.getElementById("loader-fill").style.width = "100%";
        document.getElementById("loader-fill").style.transition = "1s all";
        document.querySelector(".slider__outer__col").style.opacity = "0";
        document.getElementById("snore__dropdown").style.display = "none";
        document.getElementById("dry__mouth").style.display = "none";
        document.getElementById("disorder__two").style.display = "none";
        document.getElementById("disorder__three").style.display = "none";
        document.body.classList.remove("active__loader");
        document.body.classList.add("remove_lng");
        //document.getElementById("phoneOuterCol").style.display = "none";
    }

    window.onload = slideCounter;

    function beginHandler() {
        document.querySelector(".slider__outer__col").style.opacity = "1";
        document.querySelector(".slider__outer__col").style.zIndex = "9999";
        document.querySelector(".quiz__outer__col").style.position = "relative";
        //$('.slider__outer__col').show();
        $('.quiz_content__col').hide();
        var matched = $(".slider__outer__col .slick-slide");
        var total = matched.length;
        $('.count__total').text("/ "+total);
        $('body').addClass('loaded');
        document.body.classList.add("active__loader");
        setTimeout(function() {
            document.body.classList.remove("active__loader");
        }, 2000);
    }


    /********************************** Next Button ****************************************/

    function nextHandler() {
        $('.slider__outer__col button.slick-arrow.slick-next').trigger('click'); 
        var active = $('.slick-active').next();
        if(active.length>0) {
            $('.count').html(function(i, val) { return val*1+1 });
        }
    }


    /********************************** Previous Button ****************************************/


    function prevHandler() {
        $('.slider__outer__col button.slick-arrow.slick-prev').trigger('click'); 
        var active = $('.slick-active').prev();
        if(active.length>0) {
            $('.count').html(function(i, val) { return val*1-1 });
        }
    }


    /********************************** Quiz Functions ****************************************/

    function checkHandler() {
        var checkBox = document.getElementById("gender");
        document.getElementById("next-btn").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (checkBox.checked == true){
            document.getElementById("next-btn").style.display = "none";
            document.getElementById("next-btn").style.opacity = "0";
            document.getElementById("next-btn").style.visibility = "hidden";
            document.getElementById("next-btn").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("next-btn").style.display = "block";
            document.getElementById("next-btn").style.opacity = "1";
            document.getElementById("next-btn").style.visibility = "visible";
            document.getElementById("next-btn").style.transform = "translateX(0)";
        }
    }

    function bornHandler(event) {
        setEnteredBornVal(event.target.value);
        var year_born = document.getElementById("year_born").value;
        if (year_born.length == 4) {
            document.getElementById("born__next").style.opacity = "1";
            document.getElementById("born__next").style.visibility = "visible";
            document.getElementById("born__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("born__next").style.opacity = "0";
            document.getElementById("born__next").style.visibility = "hidden";
            document.getElementById("born__next").style.transform = "translateX(-50%)";
        }
        if(year_born < "1920") {
            document.getElementById("born__error").style.display = "block";
            document.getElementById("born__next").style.opacity = "0";
            document.getElementById("born__next").style.visibility = "hidden";
            document.getElementById("born__next").style.transform = "translateX(-50%)";
        } else if(year_born > "2021") {
            document.getElementById("born__error").style.display = "block";
            document.getElementById("born__next").style.opacity = "0";
            document.getElementById("born__next").style.visibility = "hidden";
            document.getElementById("born__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("born__error").style.display = "none";
        }
    }

    function tallHandler(event) {
        setEnteredTallVal(event.target.value);
        var tall = document.getElementById("tall").value;
        if (tall.length >= 2) {
            document.getElementById("tall__next").style.opacity = "1";
            document.getElementById("tall__next").style.visibility = "visible";
            document.getElementById("tall__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("tall__next").style.opacity = "0";
            document.getElementById("tall__next").style.visibility = "hidden";
            document.getElementById("tall__next").style.transform = "translateX(-50%)";
        }
    }

    function weightHandler(event) {
        setEnteredWeightVal(event.target.value);
        const weight_val = document.getElementById("weight").value;
        if (weight_val.length >= 2) {
            document.getElementById("weight__next").style.opacity = "1";
            document.getElementById("weight__next").style.visibility = "visible";
            document.getElementById("weight__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("weight__next").style.opacity = "0";
            document.getElementById("weight__next").style.visibility = "hidden";
            document.getElementById("weight__next").style.transform = "translateX(-50%)";
        }
    }

    function breathHandler() {
        var yes = document.getElementById("yes");
        document.getElementById("breath_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (yes.checked == true){
            document.getElementById("breath_next").style.display = "none";
            document.getElementById("breath_next").style.opacity = "0";
            document.getElementById("breath_next").style.visibility = "hidden";
            document.getElementById("breath_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("breath_next").style.display = "block";
            document.getElementById("breath_next").style.opacity = "1";
            document.getElementById("breath_next").style.visibility = "visible";
            document.getElementById("breath_next").style.transform = "translateX(0)";
        }
    }

    function sleepyHandler() {
        var sleepy = document.getElementById("sleepy").value;
        document.getElementById("sleepy_btn").style.display = "none";
        if(sleepy) {
            document.getElementById("sleepy_btn").style.display = "block";
            document.getElementById("sleepy_btn").style.opacity = "1";
            document.getElementById("sleepy_btn").style.visibility = "visible";
            document.getElementById("sleepy_btn").style.transform = "translateX(0)";
        }
    }

    function snoresHandler() {
        var snores = document.getElementById("snores");
        document.getElementById("snores_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("snores_next").style.display = "none";
            document.getElementById("snores_next").style.opacity = "0";
            document.getElementById("snores_next").style.visibility = "hidden";
            document.getElementById("snores_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("snores_next").style.display = "block";
            document.getElementById("snores_next").style.opacity = "1";
            document.getElementById("snores_next").style.visibility = "visible";
            document.getElementById("snores_next").style.transform = "translateX(0)";
        }
    }

    function toldHandler() {
        var snores = document.getElementById("told_snores");
        document.getElementById("told_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("told_next").style.display = "none";
            document.getElementById("told_next").style.opacity = "0";
            document.getElementById("told_next").style.visibility = "hidden";
            document.getElementById("told_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("told_next").style.display = "block";
            document.getElementById("told_next").style.opacity = "1";
            document.getElementById("told_next").style.visibility = "visible";
            document.getElementById("told_next").style.transform = "translateX(0)";
        }
    }

    function gaspingHandler() {
        var snores = document.getElementById("gasping");
        document.getElementById("gasping_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("gasping_next").style.display = "none";
            document.getElementById("gasping_next").style.opacity = "0";
            document.getElementById("gasping_next").style.visibility = "hidden";
            document.getElementById("gasping_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("gasping_next").style.display = "block";
            document.getElementById("gasping_next").style.opacity = "1";
            document.getElementById("gasping_next").style.visibility = "visible";
            document.getElementById("gasping_next").style.transform = "translateX(0)";
        }
    }

    function headachesHandler() {
        var snores = document.getElementById("headaches");
        document.getElementById("headaches_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("headaches_next").style.display = "none";
            document.getElementById("headaches_next").style.opacity = "0";
            document.getElementById("headaches_next").style.visibility = "hidden";
            document.getElementById("headaches_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("headaches_next").style.display = "block";
            document.getElementById("headaches_next").style.opacity = "1";
            document.getElementById("headaches_next").style.visibility = "visible";
            document.getElementById("headaches_next").style.transform = "translateX(0)";
        }
    }

    function teethHandler() {
        var snores = document.getElementById("teeth");
        document.getElementById("teeth_next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("teeth_next").style.display = "none";
            document.getElementById("teeth_next").style.opacity = "0";
            document.getElementById("teeth_next").style.visibility = "hidden";
            document.getElementById("teeth_next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("teeth_next").style.display = "block";
            document.getElementById("teeth_next").style.opacity = "1";
            document.getElementById("teeth_next").style.visibility = "visible";
            document.getElementById("teeth_next").style.transform = "translateX(0)";
        }
    }

    function diagnosedHandler() {
        document.getElementById("diagnosed_btn").style.display = "none";
        var diagnosed = document.getElementById("diagnosed");
        var diagnosedone = document.getElementById("diagnosedone");
        var diagnosedtwo = document.getElementById("diagnosedtwo");
        var diagnosedthree = document.getElementById("diagnosedthree");
        var diagnosedfour = document.getElementById("diagnosedfour");
        var diagnosedfive = document.getElementById("diagnosedfive");
        var diagnosedsix = document.getElementById("diagnosedsix");
        var diagnosedseven = document.getElementById("diagnlast");
        if(diagnosed.checked == true || diagnosedone.checked == true || diagnosedtwo.checked == true || diagnosedthree.checked == true || diagnosedfour.checked == true || diagnosedfive.checked == true || diagnosedsix.checked == true || diagnosedseven.checked == true) {
            document.getElementById("diagnosed_btn").style.display = "block";
            document.getElementById("diagnosed_btn").style.opacity = "1";
            document.getElementById("diagnosed_btn").style.visibility = "visible";
            document.getElementById("diagnosed_btn").style.transform = "translateX(0)";
        } else {
            document.getElementById("diagnosed_btn").style.display = "none";
            document.getElementById("diagnosed_btn").style.opacity = "0";
            document.getElementById("diagnosed_btn").style.visibility = "hidden";
            document.getElementById("diagnosed_btn").style.transform = "translateX(-50%)";
        }
    }

    function medicationHandler() {
        var medication = document.getElementById("medication");
        var medicationone = document.getElementById("medicationone");
        var medicationtwo = document.getElementById("medicationtwo");
        var medicationthree = document.getElementById("medicationthree");
        var medicationfour = document.getElementById("medicationfour");
        //var medicationfive = document.getElementById("medicationfive");
        var medicationsix = document.getElementById("medicationsix");
        document.getElementById("medication_next").style.display = "none";
        if(medication.checked == true || medicationone.checked == true || medicationtwo.checked == true || medicationthree.checked == true || medicationfour.checked == true || medicationsix.checked == true) {
            document.getElementById("medication_next").style.display = "block";
            document.getElementById("medication_next").style.opacity = "1";
            document.getElementById("medication_next").style.visibility = "visible";
            document.getElementById("medication_next").style.transform = "translateX(0)";
        } else {
            document.getElementById("medication_next").style.display = "none";
            document.getElementById("medication_next").style.opacity = "0";
            document.getElementById("medication_next").style.visibility = "hidden";
            document.getElementById("medication_next").style.transform = "translateX(-50%)";
        }
    }

    function disorderHandler() {
        var snores = document.getElementById("disorder");
        document.getElementById("disorder_btn").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (snores.checked == true){
            document.getElementById("disorder_btn").style.display = "none";
            document.getElementById("disorder_btn").style.opacity = "0";
            document.getElementById("disorder_btn").style.visibility = "hidden";
            document.getElementById("disorder_btn").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("disorder_btn").style.display = "block";
            document.getElementById("disorder_btn").style.opacity = "1";
            document.getElementById("disorder_btn").style.visibility = "visible";
            document.getElementById("disorder_btn").style.transform = "translateX(0)";
        }
    }

    function motivationHandler() {
        var motivation = document.getElementById("motivation");
        var motivationone = document.getElementById("motivationone");
        var motivationtwo = document.getElementById("motivationtwo");
        var motivationthree = document.getElementById("motivationthree");
        var motivationfour = document.getElementById("motivationfour");
        var motivationfive = document.getElementById("motivationfive");
        var motivationsix = document.getElementById("motivationsix");
        var motivationsev = document.getElementById("motivationsev");
        document.getElementById("motivation__next").style.display = "none";
        if(motivation.checked == true || motivationone.checked == true || motivationtwo.checked == true || motivationthree.checked == true || motivationfour.checked == true || motivationfive.checked == true || motivationsix.checked == true || motivationsev.checked == true) {
            document.getElementById("motivation__next").style.display = "block";
            document.getElementById("motivation__next").style.opacity = "1";
            document.getElementById("motivation__next").style.visibility = "visible";
            document.getElementById("motivation__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("motivation__next").style.display = "none";
            document.getElementById("motivation__next").style.opacity = "0";
            document.getElementById("motivation__next").style.visibility = "hidden";
            document.getElementById("motivation__next").style.transform = "translateX(-50%)";
        }
    }

    function discussedHandler() {
        var discussed = document.getElementById("discussed");
        var discussedone = document.getElementById("discussedone");
        var discussedtwo = document.getElementById("discussedtwo");
        var discussedthree = document.getElementById("discussedthree");
        var discussedfour = document.getElementById("discussedfour");
        document.getElementById("discussed__next").style.display = "none";
        if(discussed.checked == true || discussedone.checked == true || discussedtwo.checked == true || discussedthree.checked == true || discussedfour.checked == true) {
            document.getElementById("discussed__next").style.display = "block";
            document.getElementById("discussed__next").style.opacity = "1";
            document.getElementById("discussed__next").style.visibility = "visible";
            document.getElementById("discussed__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("discussed__next").style.display = "none";
            document.getElementById("discussed__next").style.opacity = "0";
            document.getElementById("discussed__next").style.visibility = "hidden";
            document.getElementById("discussed__next").style.transform = "translateX(-50%)";
        }
    }

    function describeHandler() {
        var describe = document.getElementById("describe");
        document.getElementById("describe__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (describe.checked == true){
            document.getElementById("describe__next").style.display = "none";
            document.getElementById("describe__next").style.opacity = "0";
            document.getElementById("describe__next").style.visibility = "hidden";
            document.getElementById("describe__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("describe__next").style.display = "block";
            document.getElementById("describe__next").style.opacity = "1";
            document.getElementById("describe__next").style.visibility = "visible";
            document.getElementById("describe__next").style.transform = "translateX(0)";
        }
    }

    function averageHandler() {
        var average = document.getElementById("average");
        document.getElementById("average__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (average.checked == true){
            document.getElementById("average__next").style.display = "none";
            document.getElementById("average__next").style.opacity = "0";
            document.getElementById("average__next").style.visibility = "hidden";
            document.getElementById("average__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("average__next").style.display = "block";
            document.getElementById("average__next").style.opacity = "1";
            document.getElementById("average__next").style.visibility = "visible";
            document.getElementById("average__next").style.transform = "translateX(0)";
        }
    }

    function sleepingHandler() {
        var sleeping = document.getElementById("sleeping");
        document.getElementById("sleeping__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (sleeping.checked == true){
            document.getElementById("sleeping__next").style.display = "none";
            document.getElementById("sleeping__next").style.opacity = "0";
            document.getElementById("sleeping__next").style.visibility = "hidden";
            document.getElementById("sleeping__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("sleeping__next").style.display = "block";
            document.getElementById("sleeping__next").style.opacity = "1";
            document.getElementById("sleeping__next").style.visibility = "visible";
            document.getElementById("sleeping__next").style.transform = "translateX(0)";
        }
    }

    function severeHandler() {
        var severe = document.getElementById("severe");
        document.getElementById("severe__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (severe.checked == true){
            document.getElementById("severe__next").style.display = "none";
            document.getElementById("severe__next").style.opacity = "0";
            document.getElementById("severe__next").style.visibility = "hidden";
            document.getElementById("severe__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("severe__next").style.display = "block";
            document.getElementById("severe__next").style.opacity = "1";
            document.getElementById("severe__next").style.visibility = "visible";
            document.getElementById("severe__next").style.transform = "translateX(0)";
        }
    }

    function wakingHandler() {
        var waking = document.getElementById("waking");
        document.getElementById("waking__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (waking.checked == true){
            document.getElementById("waking__next").style.display = "none";
            document.getElementById("waking__next").style.opacity = "0";
            document.getElementById("waking__next").style.visibility = "hidden";
            document.getElementById("waking__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("waking__next").style.display = "block";
            document.getElementById("waking__next").style.opacity = "1";
            document.getElementById("waking__next").style.visibility = "visible";
            document.getElementById("waking__next").style.transform = "translateX(0)";
        }
    }

    function extendHandler() {
        var extend = document.getElementById("extend");
        document.getElementById("extend__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (extend.checked == true){
            document.getElementById("extend__next").style.display = "none";
            document.getElementById("extend__next").style.opacity = "0";
            document.getElementById("extend__next").style.visibility = "hidden";
            document.getElementById("extend__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("extend__next").style.display = "block";
            document.getElementById("extend__next").style.opacity = "1";
            document.getElementById("extend__next").style.visibility = "visible";
            document.getElementById("extend__next").style.transform = "translateX(0)";
        }
    }

    function worriedHandler() {
        var worried = document.getElementById("worried");
        document.getElementById("worried__next").style.display = "none";
        var element = document.querySelector(".slick-slide");
        element.classList.add("btn-active");
        if (worried.checked == true){
            document.getElementById("worried__next").style.display = "none";
            document.getElementById("worried__next").style.opacity = "0";
            document.getElementById("worried__next").style.visibility = "hidden";
            document.getElementById("worried__next").style.transform = "translateX(-50%)";
        } else {
            document.getElementById("worried__next").style.display = "block";
            document.getElementById("worried__next").style.opacity = "1";
            document.getElementById("worried__next").style.visibility = "visible";
            document.getElementById("worried__next").style.transform = "translateX(0)";
        }
    }

    function phoneHandler() {
        var ph_number = document.getElementById("phonenumber").value;
        if (ph_number.length == 8) {
            document.getElementById("representative__next").style.opacity = "1";
            document.getElementById("representative__next").style.visibility = "visible";
            document.getElementById("representative__next").style.transform = "translateX(0)";
        } else {
            document.getElementById("representative__next").style.opacity = "0";
            document.getElementById("representative__next").style.visibility = "hidden";
            document.getElementById("representative__next").style.transform = "translateX(-50%)";
        }
    }


/********************************** Conditional Question ****************************************/


    $(document).ready(function () {

        $('#prevbtnNew').hide();
        $('#prev-btn').hide();
        $('.existNum').hide();
        $('.emptyNum').hide();

        $(".snore__dropdown button").click(function(){
            $('.snore_slct').addClass('first_step');
        });
    
        $(".dry__mouth button").click(function(){
            $('.snore_slct').addClass('sec_step');
        });

        $(".three__one button").click(function(){
            $('.dsrdr__wrp').addClass('f_step');
        });

        $(".three__two button").click(function(){
            $('.dsrdr__wrp').addClass('s_step');
        });

        $("button#told_snores").click(function(){
        	var radioValue = $(this).val();
            if(radioValue == "Yes") {
                $('.snore__dropdown').fadeIn();
                $('.snore_select').hide();
                $('.dry__mouth').fadeIn();
                localStorage.setItem('mySnore', "-highrisk");
                $('.snore_slct').addClass('main__step');
                $('#told_next').hide();
            }
            if(radioValue == "No") {
                $('.snore__dropdown').hide();
                $('.snore_select').fadeIn();
                $('.dry__mouth').hide();
                localStorage.setItem('mySnore', "-lowrisk");
                $('.snore_slct').removeClass('main__step');
                $('#told_next').show();
                $('#told_next').css('transform','translateX(0px)');
            }
        });

        $('.prev_snore__dropdown').click(function() {
            $('.snore_slct').removeClass('main__step');
            $('.snore__dropdown').hide();
            $('.dry__mouth').hide();
            $('.snore_select').fadeIn();
            $('.snore_slct').removeClass('first_step');
            $('.snore_slct').removeClass('sec_step');
        });

        $('#disorder').click(function(){
        	var disorderValue = $(this).val();
            if(disorderValue == "Yes") {
                $('.disorder__one').hide();
                $('.disorder__two').fadeIn();
                $('.dsrdr__wrp').addClass('first-step');
                $('#disorder_btn').hide();
            }
            if(disorderValue == "No") {
                $('.disorder__one').fadeIn();
                $('.disorder__two').hide();
                $('.dsrdr__wrp').removeClass('first-step');
                $('#disorder_btn').show();
                $('#disorder_btn').css('transform','translateX(0px)');
            }
        });

        $('#disorder__two__btn').click(function(){
        	var disorder__two__btn = $(this).val();
            if(disorder__two__btn == "Yes") {
                $('.disorder__three').fadeIn();
                $('.disorder__two').hide();
                $('.dsrdr__wrp').removeClass('first-step');
                $('.dsrdr__wrp').addClass('sec-step');
                $('#disorder_btn').hide();
            }
            if(disorder__two__btn == "No") {
                $('.disorder__three').hide();
                $('.disorder__two').fadeIn();
                $('.dsrdr__wrp').addClass('first-step');
                $('.dsrdr__wrp').removeClass('sec-step');
                $('#disorder_btn').show();
                $('#disorder_btn').css('transform','translateX(0px)');
            }
        });

        $('.prev__disorder__one').click(function() {
            $('.disorder__three').hide();
            $('.disorder__two').hide();
            $('.disorder__one').fadeIn();
            $(this).hide();
            $('.dsrdr__wrp').removeClass('first-step');
            $('.dsrdr__wrp').removeClass('sec-step');
        });

        $('.prev__disorder__two').click(function() {
            $('.disorder__three').hide();
            $('.disorder__two').fadeIn();
            $(this).hide();
            $('.dsrdr__wrp').addClass('first-step');
            $('.dsrdr__wrp').removeClass('sec-step');
            $('.dsrdr__wrp').removeClass('f_step');
            $('.dsrdr__wrp').removeClass('s_step');
        });

        $('.card__inner__content .btn').click(function () {
            $(this).addClass('active-click').siblings('.btn').removeClass('active-click');
        });

        $("#tall").on("keypress keyup blur paste", function(event) {
            var that = this;
    
            if (event.type === "paste") {
                setTimeout(function() {
                    $(that).val($(that).val().replace(/[^\d].+/, ""));
                }, 100);
            } else {
        
                if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                } else {
                    $(this).val($(this).val().replace(/[^\d].+/, ""));
                }
            }
        
        });

        $("button#representative").click(function(){
        	var representativeValue = $(this).val();
            if(representativeValue == "Yes") {
                $('.phoneOuterCol').fadeIn();
                $('.representative_discuss').hide();
                $('#prevbtnNew').fadeIn();
                $('#prev-btnLast').hide();
                $('#prevbtnNew').removeClass('prevRemove');
                $('#prev-btnLast').addClass('prevRemove');
                const phone_number = localStorage.getItem('phoneNumber');
                if(phone_number) {
                    $('.existNum').show();
                    $('.emptyNum').hide();
                    $('.phoneOuterCol input#phonenumber').val(phone_number);
                } else {
                    $('.existNum').hide();
                    $('.emptyNum').show();
                    $('.phoneOuterCol input#phonenumber').val();
                }
            }
            if(representativeValue == "No") {
                $('.phoneOuterCol').hide();
                $('.representative_discuss').fadeIn();
                document.getElementById("representative__next").style.opacity = "1";
                document.getElementById("representative__next").style.visibility = "visible";
                document.getElementById("representative__next").style.transform = "translateX(0)";
                $('#prevbtnNew').hide();
                $('#prev-btnLast').fadeIn();
                $('#prevbtnNew').addClass('prevRemove');
                $('#prev-btnLast').removeClass('prevRemove');
            }
        });

        $("#diagnlast").on('change', function() {
            if(this.checked) {
                $(this).parent('label').siblings('label').find('input').prop('checked', false);
                $(this).parent('label').siblings('label').find('input').attr("disabled", true);
            } else {
                $(this).parent('label').siblings('label').find('input').removeAttr("disabled");
            }
        });

        $("#medicationsix").on('change', function() {
            if(this.checked) {
                $(this).parent('label').siblings('label').find('input').prop('checked', false);
                $(this).parent('label').siblings('label').find('input').attr("disabled", true);
            } else {
                $(this).parent('label').siblings('label').find('input').removeAttr("disabled");
            }
        });

        $("#discussedfour").on('change', function() {
            if(this.checked) {
                $(this).parent('label').siblings('label').find('input').prop('checked', false);
                $(this).parent('label').siblings('label').find('input').attr("disabled", true);
            } else {
                $(this).parent('label').siblings('label').find('input').removeAttr("disabled");
            }
        });

        $('#prevbtnNew').click(function() {
            $(this).hide();
            $('#prev-btnLast').fadeIn();
            $(this).addClass('prevRemove');
            $('#prev-btnLast').removeClass('prevRemove');
            $('.phoneOuterCol').hide();
            $('.representative_discuss').fadeIn();
        });
    });



/********************************** API Call ****************************************/


    let history = useHistory();
    const redirectHandler = (event) => {
        event.preventDefault();
        var radio_val = $('.gender__name .btn.active-click').val();
        var born_val = $('.btn-group input[name="year_of_birth"]').val();
        var tall_val = $('.btn-group input[name="height"]').val();
        var weight_val = $('.btn-group input[name="weight"]').val();
        var tall_valnew = tall_val/100 ;
        var bmi = weight_val / (tall_valnew * tall_valnew) ;
        bmi = bmi.toFixed(2);
        var breath_while = $('.breath_while .btn.active-click').val();
        var night__feel = $('.night__feel .btn.active-click').val();
        var next_snore = $('.next_snore .btn.active-click').val();
        var snore_select = $('.snore_select .btn.active-click').val();
        var snore__dropdown = $('.snore__dropdown .btn.active-click').val();
        var dry__mouth = $('.dry__mouth .btn.active-click').val();
        var gasping_breath = $('.gasping_breath .btn.active-click').val();
        var morning_headaches = $('.morning_headaches .btn.active-click').val();
        var grind_teeth = $('.grind_teeth .btn.active-click').val();
        var diagnosed_conditions = $('.diagnosed_conditions:checked').map(function() {
            return this.value;
        }).get().join('; ');
        var medications_val = $('.medications_val:checked').map(function() {
            return this.value;
        }).get().join('; ');
        var disorder__one = $('.disorder__one .btn.active-click').val();
        var disorder__two = $('.disorder__two .btn.active-click').val();
        var disorder__three = $('.three__one .btn.active-click').val();
        var disorder__three__inner = $('.three__two .btn.active-click').val();
        var motivation_sleep = $('.motivation_sleep:checked').map(function() {
            return this.value;
        }).get().join('; ');
        var discussed_sleep = $('.discussed_sleep:checked').map(function() {
            return this.value;
        }).get().join('; ');
        var describe_your_sleep = $('.describe_your_sleep .btn.active-click').val();
        var insomnia_sleep_per_night = $('.insomnia_sleep_per_night .btn.active-click').val();
        var insomnia_pain_trouble = $('.insomnia_pain_trouble .btn.active-click').val();
        var insomnia_falling_asleep = $('.insomnia_falling_asleep .btn.active-click').val();
        var insomnia_waking = $('.insomnia_waking .btn.active-click').val();
        var insomnia_sleep_negatively = $('.insomnia_sleep_negatively .btn.active-click').val();
        var insomnia_worried_sleep = $('.insomnia_worried_sleep .btn.active-click').val();
        const name_val = localStorage.getItem('myData');
        const last_name = localStorage.getItem('lastName');
        const phone_number = localStorage.getItem('phoneNumber');
        const email_val = localStorage.getItem('emailVal');
        var representative_discuss = $('.representative_discuss .btn.active-click').val();
        var lastNumber = $('.phoneOuterCol input#phonenumber').val();

        var snorval;
        var dryVal;
        var disorder__twoVal;
        var disorder__threeVal;
        var disorder__three__innerVal;
        if(snore__dropdown) {
            snorval = snore__dropdown;
        } else {
            snorval = '';
        }

        if(dry__mouth) {
            dryVal = dry__mouth;
        } else {
            dryVal = '';
        }

        if(disorder__two) {
            disorder__twoVal = disorder__two;
        } else {
            disorder__twoVal = '';
        }

        if(disorder__three) {
            disorder__threeVal = disorder__three;
        } else {
            disorder__threeVal = '';
        }

        if(disorder__three__inner) {
            disorder__three__innerVal = disorder__three__inner;
        } else {
            disorder__three__innerVal = '';
        }

        var phNew;
        if(lastNumber) {
            phNew = lastNumber;
        } else {
            phNew = phone_number;
        }
        fetch(REACT_APP_API_URL, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': REACT_APP_API
            },  
            body: CryptoJS.AES.encrypt(JSON.stringify({
                email: email_val,
                first_name: name_val,
                last_name: last_name,
                mobilephone: phone_number,
                gender: radio_val,
                year_of_birth: enteredBornVal,
                weight_in_kgs: enteredWeightVal,
                height_in_cms: enteredTallVal,
                bmi: bmi,
                have_you_been_told_that_you_hold_your_breath_while_sleeping: breath_while,
                even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day: night__feel,
                do_you_sleep_next_to_someone_who_snores: next_snore,
                have_you_been_told_that_you_snore: snore_select,
                which_position_do_you_snore_in_while_sleeping: snorval,
                do_you_wake_with_a_dry_mouth: dryVal,
                do_you_wake_gasping_for_breath: gasping_breath,
                do_you_wake_up_in_the_morning_with_headaches: morning_headaches,
                do_you_grind_your_teeth_at_night_or_wake_with_an_aching_jaw: grind_teeth,
                are_you_suffering_or_diagnosed_with_these_conditions: diagnosed_conditions,
                are_you_on_any_long_term_medications_for_these_conditions: medications_val,
                heard_of_sleep_apnea: disorder__one,
                diagnosed_sleepapnea: disorder__twoVal,
                diagnosed_ahi: disorder__threeVal,
                tried_cpap_therapy: disorder__three__innerVal,
                motivation_for_improving_sleep: motivation_sleep,
                discussed_sleep_related_issues_with_any_of_these: discussed_sleep,
                how_would_you_describe_your_sleep: describe_your_sleep,
                insomnia_hours_of_sleep_per_night: insomnia_sleep_per_night,
                insomnia_pain_related_sleep_trouble: insomnia_pain_trouble,
                insomnia_difficulty_falling_asleep: insomnia_falling_asleep,
                insomnia_difficulty_to_stay_asleep: ' ',
                insomnia_waking_up_too_early: insomnia_waking,
                insomnia_sleep_negatively_impacting_quality_of_life: insomnia_sleep_negatively,
                insomnia_worried_about_your_sleep: insomnia_worried_sleep,
                sleep_coach_allow_to_call: '',
                sleep_coach_preferred_time: '',
                sleep_coach_allow_to_call: representative_discuss,
                pageUrl: "https://sleepquiz.resmed.sg",
                pageName: "ResMed Singapore Sleep Quiz",
                country: "sg"
                }), REACT_APP_PHRASE)
            }).then(function(response) {
                return response.json();
            }).then(function(data) {
                console.log('Created Gist:', data);
                //console.log(radio_val, born_val, tall_val, weight_val, bmi, breath_while, night__feel, next_snore, snore_select, snore__dropdown, dry__mouth, gasping_breath, morning_headaches, grind_teeth, diagnosed_conditions, medications_val, disorder__one, disorder__two, disorder__three, disorder__three__inner, motivation_sleep, discussed_sleep, describe_your_sleep, insomnia_sleep_per_night, insomnia_pain_trouble, insomnia_falling_asleep, insomnia_waking, insomnia_sleep_negatively, insomnia_worried_sleep, representative_discuss);
                if(data.data.result.insomniaRisk) {
                    var insomniarisk = data.data.result.insomniaRisk;
                    var insomniarisknew = insomniarisk.replace(/\s+/g, '').toLowerCase();
                    localStorage.setItem('insomniarisk', insomniarisknew);
                } else {
                    localStorage.setItem('insomniarisk', '');
                }
                if(data.data.result.snoreType) {
                    var snoreType = data.data.result.snoreType;
                    var snoreTypenew = snoreType.replace(/\s+/g, '').toLowerCase();
                    localStorage.setItem('snoreType', snoreTypenew);
                } else {
                    localStorage.setItem('snoreType', '');
                }
                if(data.data.result.osaRisk) {
                    var osaRisk = data.data.result.osaRisk;
                    var osaRisknew = osaRisk.replace(/\s+/g, '').toLowerCase();
                    localStorage.setItem('osaRisk', osaRisknew);
                } else {
                    localStorage.setItem('osaRisk', '');
                }
                window.location.href = '/result';
            });
            document.body.classList.add("active__loader");
    }
    const { t } = useTranslation();

    return (
        <div>
            <div className="quiz__outer__col">
                <div id="loading__icon" style={{backgroundImage: `url(${loadIcon})`}}>
                </div>
                <div className="container">
                    <div className="quiz__inner__col">
                        <div className="quiz_header__col">
                            <img src={QuizLogo} />
                        </div>
                        <div className="quiz_content__col">
                            <div className="quiz_content__icon">
                                <img src={QuizContentIcon} />
                            </div>
                            <div className="quiz_content__wrapper">
                                <div className="quiz_content__heading">
                                    <h2>{t("hello")}</h2>
                                </div>
                                <div className="quiz_content__description">
                                    <p>{t("quizdesc")}</p>
                                </div>
                                <div className="quiz_content__btn">
                                    <button id="beginBtn" type="button" className="gtm-click" value="start" onClick={beginHandler} data-event-label="sleep quiz start">
                                    {t("begin")}
                                    <Icon name="arrow-right" size={ 12 } color="#9d2872"/> 
                                    </button>
                                </div>
                            </div>
                            <div className="quiz_content__loader__wrapper">
                                <div className="loader-outline">
                                    <div id="loader-fill" className="loader-fill"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider__outer__col" style={quizBox1}>
                <div id="loading__icon" style={quizBox}>
                </div>
                <div className="container">
                    <Slider {...settings}> 
                        <div className="card-wrapper">
                            <div className='countNum'>1</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={GenderIcon} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left hide-all">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content gender__name">
                                    <h4 id="card_title-17" className="card__title">{t("gender")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="gender" type="button" role="radio" aria-checked="false" name="gender"  className="btn-primary flex-4 btn " value="Male" onClick={checkHandler}>
                                            {t("male")}
                                        </button>
                                        <button id="gender" type="button" role="radio" aria-checked="false" name="gender" className="btn-primary flex-4 btn " value="Female" onClick={checkHandler}>
                                            {t("female")}
                                        </button>
                                    </div>
                                </div>
                                <button id="next-btn" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="1_gender">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn" disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>2</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={BornIcon} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("born")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <input type="text" id="year_born" name="year_of_birth" maxLength={4} placeholder="YYYY" value = {enteredBornVal} onChange={bornHandler} />
                                    </div>
                                    <span id="born__error" className="born__error" style={{display: 'none'}}>Please enter a valid year</span>
                                </div>
                                <button type="button" className="next-btn btn gtm-click" id="born__next" disabled="" value="" onClick={nextHandler}  data-event-label="2_birth_year">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn" disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>3</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={HeightIcon} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("tall")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <input type="text" name="height" id="tall" maxLength={3} placeholder="cm" value = {enteredTallVal} onChange={tallHandler}/>
                                    </div>
                                </div>
                                <button type="button" className="next-btn btn gtm-click" id="tall__next" disabled="" value="" onClick={nextHandler} data-event-label="3_height">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>4</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={WeightIcon} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("weight")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <input type="text" id="weight" name="weight" placeholder="kg" maxLength={3} value = {enteredWeightVal} onChange={weightHandler}/>
                                    </div>
                                </div>
                                <button type="button" className="next-btn btn gtm-click" id="weight__next" disabled="" value="" onClick={nextHandler} data-event-label="4_weight">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>5</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={NotBreathIcon} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content breath_while">
                                    <h4 id="card_title-17" className="card__title">{t("have_you_been_told_that_you_hold_your_breath_while_sleeping")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="yes" type="button" role="radio" aria-checked="false" name="have_you_been_told_that_you_hold_your_breath_while_sleeping" className="btn-primary flex-4 btn " value="Yes" onClick={breathHandler}>
                                            {t("yes")}
                                        </button>
                                        <button id="yes" type="button" role="radio" aria-checked="false" name="have_you_been_told_that_you_hold_your_breath_while_sleeping" className="btn-primary flex-4 btn " value="No" onClick={breathHandler}>
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                                <button id="breath_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="5_hold_breath">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper mob_height">
                            <div className='countNum'>6</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={SleepyNextDay} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content night__feel">
                                    <h4 id="card_title-17" className="card__title">{t("even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="sleepy" type="button" role="radio" aria-checked="false" name="even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day" className="btn-primary flex-4 btn " value="Extremely" onClick={sleepyHandler}>
                                            {t("extremely")}
                                        </button>
                                        <button id="sleepy" type="button" role="radio" aria-checked="false" name="even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day" className="btn-primary flex-4 btn " value="Very" onClick={sleepyHandler}>
                                            {t("very")}
                                        </button>
                                        <button id="sleepy" type="button" role="radio" aria-checked="false" name="even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day" className="btn-primary flex-4 btn " value="Moderately" onClick={sleepyHandler}>
                                            {t("moderately")}
                                        </button>
                                        <button id="sleepy" type="button" role="radio" aria-checked="false" name="even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day" className="btn-primary flex-4 btn " value="Slightly" onClick={sleepyHandler}>
                                            {t("slighty")}
                                        </button>
                                        <button id="sleepy" type="button" role="radio" aria-checked="false" name="even_after_sleeping_through_the_night_do_you_feel_sleepy_during_the_day" className="btn-primary flex-4 btn " value="Not at all" onClick={sleepyHandler}>
                                            {t("not_at_all")}
                                        </button>
                                    </div>
                                </div>
                                <button id="sleepy_btn" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="6_sleepy_during_day">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>7</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={NearSnorer} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content next_snore">
                                    <h4 id="card_title-17" className="card__title">{t("do_you_sleep_next_to_someone_who_snores")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="snores" type="button" role="radio" aria-checked="false" name="do_you_sleep_next_to_someone_who_snores" className="btn-primary flex-4 btn " value="Yes" onClick={snoresHandler}>
                                            {t("yes")}
                                        </button>
                                        <button id="snores" type="button" role="radio" aria-checked="false" name="do_you_sleep_next_to_someone_who_snores" className="btn-primary flex-4 btn " value="No" onClick={snoresHandler}>
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                                <button id="snores_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="7_partner_snore">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper snore_slct">
                            <div className='countNum'>8</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={ToldToSnore} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content two__steps">
                                    <div className="snore_select" id="snore_select">
                                        <h4 id="card_title-17" className="card__title">{t("have_you_been_told_that_you_snore")}?</h4>
                                        <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                            <button id="told_snores" type="button" role="radio" aria-checked="false" name="have_you_been_told_that_you_snore" className="btn-primary flex-4 btn told__yes" value="Yes">
                                                {t("yes")}
                                            </button>
                                            <button id="told_snores" type="button" role="radio" aria-checked="false" name="have_you_been_told_that_you_snore" className="btn-primary flex-4 btn told__no" value="No" onClick={toldHandler}>
                                                {t("no")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="snore__dropdown" id="snore__dropdown">
                                        <h4 id="card_title-17" className="card__title">{t("which_position_do_you_snore_in_while_sleeping")}</h4>
                                        <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                            <button id="sleepys" type="button" role="radio" aria-checked="false" name="which_position_do_you_snore_in_while_sleeping" className="btn-primary flex-4 btn " value="On my back">
                                                {t("on_my_back")}
                                            </button>
                                            <button id="sleepys" type="button" role="radio" aria-checked="false" name="which_position_do_you_snore_in_while_sleeping" className="btn-primary flex-4 btn " value="On my side">
                                                {t("on_my_side")}
                                            </button>
                                            <button id="sleepys" type="button" role="radio" aria-checked="false" name="which_position_do_you_snore_in_while_sleeping" className="btn-primary flex-4 btn " value="In any position">
                                                {t("in_any_position")}
                                            </button>
                                            <button id="sleepys" type="button" role="radio" aria-checked="false" name="which_position_do_you_snore_in_while_sleeping" className="btn-primary flex-4 btn " value="Can't recall">
                                                {t("cant_recall")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="dry__mouth" id="dry__mouth">
                                        <h4 id="card_title-17" className="card__title">{t("do_you_wake_with_a_dry_mouth")}?</h4>
                                        <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                            <button id="dry__mouth__btn" type="button" role="radio" aria-checked="false" name="do_you_wake_with_a_dry_mouth" className="btn-primary flex-4 btn " value="Yes">
                                                {t("yes")}
                                            </button>
                                            <button id="dry__mouth__btn" type="button" role="radio" aria-checked="false" name="do_you_wake_with_a_dry_mouth" className="btn-primary flex-4 btn " value="No">
                                                {t("no")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button id="told_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="8_snore">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn prev_snore__dropdown" disabled="" value=""  style={{display: 'none'}}>
                                    {t("prev")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn prevsnore_drp_final" disabled="" value="" onClick={prevHandler}>
                                    {t("prev")}
                                </button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>9</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={OutOfBreath} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content gasping_breath">
                                    <h4 id="card_title-17" className="card__title">{t("do_you_wake_gasping_for_breath")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="gasping" type="button" role="radio" aria-checked="false" name="do_you_wake_gasping_for_breath" className="btn-primary flex-4 btn " value="Yes" onClick={gaspingHandler}>
                                            {t("yes")}
                                        </button>
                                        <button id="gasping" type="button" role="radio" aria-checked="false" name="do_you_wake_gasping_for_breath" className="btn-primary flex-4 btn " value="No" onClick={gaspingHandler}>
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                                <button id="gasping_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="9_gasp_for_breath">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>10</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={headache} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content morning_headaches">
                                    <h4 id="card_title-17" className="card__title">{t("do_you_wake_up_in_the_morning_with_headaches")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="headaches" type="button" role="radio" aria-checked="false" name="do_you_wake_up_in_the_morning_with_headaches" className="btn-primary flex-4 btn " value="Yes" onClick={headachesHandler}>
                                            {t("yes")}
                                        </button>
                                        <button id="headaches" type="button" role="radio" aria-checked="false" name="do_you_wake_up_in_the_morning_with_headaches" className="btn-primary flex-4 btn " value="No" onClick={headachesHandler}>
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                                <button id="headaches_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="10_headaches">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>11</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={pain} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content grind_teeth">
                                    <h4 id="card_title-17" className="card__title">{t("do_you_grind_your_teeth_at_night_or_wake_with_an_aching_jaw")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="teeth" type="button" role="radio" aria-checked="false" name="do_you_grind_your_teeth_at_night_or_wake_with_an_aching_jaw" className="btn-primary flex-4 btn " value="Yes" onClick={teethHandler}>
                                            {t("yes")}
                                        </button>
                                        <button id="teeth" type="button" role="radio" aria-checked="false" name="do_you_grind_your_teeth_at_night_or_wake_with_an_aching_jaw" className="btn-primary flex-4 btn " value="No" onClick={teethHandler}>
                                            {t("no")}
                                        </button>
                                    </div>
                                </div>
                                <button id="teeth_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="11_grind_your_teeth">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>12</div>
                            <form className="card card-main card-default multiple-choice cpod-qtn">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={doctor} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("are_you_suffering_or_diagnosed_with_these_conditions")}? <span>{t("choice_notice")}</span></h4>
                                    
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Hypertension/high blood pressure" id="diagnosed" onClick={diagnosedHandler}/>
                                            <span>{t("high_blood_pressure")}</span>
                                        </label>    
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Type 2 Diabetes" id="diagnosedone" onClick={diagnosedHandler}/>
                                            <span>{t("type_diabetes")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="COPD" id="diagnosedtwo" onClick={diagnosedHandler}/>
                                            <span>{t("copd")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Heart Failure" id="diagnosedthree" onClick={diagnosedHandler}/>
                                            <span>{t("heart_failure")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Mood disorders/depression" id="diagnosedfour" onClick={diagnosedHandler}/>
                                            <span>{t("mood_disorders")}</span>
                                        </label>
                                        <label>      
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Chronic Pain" id="diagnosedfive" onClick={diagnosedHandler}/>
                                            <span>{t("chronic_pain")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="Heartburn acid/reflux" id="diagnosedsix" onClick={diagnosedHandler}/>
                                            <span>{t("heartburn")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" name="are_you_suffering_or_diagnosed_with_these_conditions" className="diagnosed_conditions" value="none" id="diagnlast" onClick={diagnosedHandler}/>
                                            <span>{t("none")}</span>
                                        </label>
                                    </div>
                                    <div className="noteTextQuiz">
                                        <p>{t("quiznotetext")}</p>
                                    </div>
                                </div>
                                <button id="diagnosed_btn" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="12_other_diagnosed">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>13</div>
                            <form className="card card-main card-default multiple-choice">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={longtreatment} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("are_you_on_any_long_term_medications_for_these_conditions")}? <span>{t("choice_notice")}</span></h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Hypertension/high blood pressure" id="medication" onChange={medicationHandler}/>
                                            <span>{t("high_blood_pressure")}</span>
                                        </label>    
                                        <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Diabetes" id="medicationone" onChange={medicationHandler}/>
                                            <span>{t("diabetes")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Opioids to treat chronic pain" id="medicationtwo" onChange={medicationHandler}/>
                                            <span>{t("opioids_to_treat_chronic_pain")}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Mood disorders/depression" id="medicationthree" onChange={medicationHandler}/>
                                            <span>{t("mood_disorders")}</span>
                                        </label>
                                        <label>      
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Heartburn acid/reflux" id="medicationfour" onChange={medicationHandler}/>
                                            <span>{t("heartburn")}</span>
                                        </label>
                                        {/* <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="Other" id="medicationfive" onChange={medicationHandler}/>
                                            <span>{t("other")}</span>
                                        </label> */}
                                        <label>
                                            <input type="checkbox" className="medications_val" name="are_you_on_any_long_term_medications_for_these_conditions" value="None" id="medicationsix" onChange={medicationHandler}/>
                                            <span>{t("none")}</span>
                                        </label>
                                    </div>
                                    <div className="noteTextQuiz">
                                        <p>{t("quiznotetext")}</p>
                                    </div>
                                </div>
                                <button id="medication_next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="13_other_medical_condition">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper dsrdr__wrp">
                            <div className='countNum'>14</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={SleepApnea} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <div className="disorder__one">
                                        <h4 id="card_title-17" className="card__title">{t("heard_of_sleep_apnea")}?</h4>
                                        <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                            <button id="disorder" type="button" role="radio" aria-checked="false" name="heard_of_sleep_apnea" className="btn-primary flex-4 btn " value="Yes">
                                                {t("yes")}
                                            </button>
                                            <button id="disorder" type="button" role="radio" aria-checked="false" name="heard_of_sleep_apnea" className="btn-primary flex-4 btn " value="No" onClick={disorderHandler}>
                                                {t("no")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="disorder__two" id="disorder__two">
                                        <h4 id="card_title-17" className="card__title">{t("diagnosed_sleepapnea")}?</h4>
                                        <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                            <button id="disorder__two__btn" type="button" role="radio" aria-checked="false" name="diagnosed_sleepapnea" className="btn-primary flex-4 btn " value="Yes">
                                                {t("yes")}
                                            </button>
                                            <button id="disorder__two__btn" type="button" role="radio" aria-checked="false" name="diagnosed_sleepapnea" className="btn-primary flex-4 btn " value="No" onClick={disorderHandler}>
                                                {t("no")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="disorder__three" id="disorder__three">
                                        <div className="disorder__three__inner three__one">
                                            <h4 id="card_title-17" className="card__title">{t("diagnosed_ahi")}</h4>
                                            <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                                <button id="sleepyone" type="button" role="radio" aria-checked="false" name="diagnosed_ahi" className="btn-primary flex-4 btn " value="AHI < 20">
                                                    {t("AHI_less_20")}
                                                </button>
                                                <button id="sleepyone" type="button" role="radio" aria-checked="false" name="diagnosed_ahi" className="btn-primary flex-4 btn " value="AHI > 20">
                                                    {t("AHI_greater_20")}
                                                </button>
                                                <button id="sleepyone" type="button" role="radio" aria-checked="false" name="diagnosed_ahi" className="btn-primary flex-4 btn " value="Don't recall">
                                                    {t("dont_recall")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="disorder__three__inner three__two">
                                            <h4 id="card_title-17" className="card__title">{t("tried_cpap_therapy")}?</h4>
                                            <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                                <button type="button" role="radio" aria-checked="false" name="tried_cpap_therapy" className="btn-primary flex-4 btn " value="Yes">
                                                    {t("yes")}
                                                </button>
                                                <button type="button" role="radio" aria-checked="false" name="tried_cpap_therapy" className="btn-primary flex-4 btn " value="No">
                                                    {t("no")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button id="disorder_btn" type="button" className="next-btn btn gtm-click" disabled="" value="" style={{display: 'none'}} onClick={nextHandler} data-event-label="14_heard_of_sleep_apnea">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn prev__disorder__one" disabled="" value="" style={{display:'none'}}>{t("prev")}</button>
                                <button id="prev-btn" type="button" className="prev-btn btn prev__disorder__two" disabled="" value="" style={{display:'none'}}>{t("prev")}</button>
                                <button id="prev-btn" type="button" className="prev-btn btn prev__disorder__final" disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>15</div>
                            <form className="card card-main card-default multiple-choice improving_sleep">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={whatcaused} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("motivation_for_improving_sleep")} <span>{t("choice_notice")}</span></h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <label>
                                            <input id="motivation" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="A loved one's suggestion/partner wants me to stop snoring" onClick={motivationHandler} />
                                            <span>{t("a_loved_ones_suggestion_partner_wants_me_to_stop_snoring")}</span>
                                        </label>    
                                        <label>
                                            <input id="motivationone" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Doctor's recommendation" onClick={motivationHandler} />
                                            <span>{t("doctors_recommendation")}</span>
                                        </label>
                                        <label>
                                            <input id="motivationtwo" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Low energy and fatigue" onClick={motivationHandler} />
                                            <span>{t("low_energy_and_fatigue")}</span>
                                        </label>
                                        <label>
                                            <input id="motivationthree" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Work productivity or performance issues" onClick={motivationHandler} />
                                            <span>{t("work_productivity_or_perfomance_issues")}</span>
                                        </label>
                                        <label>
                                            <input id="motivationfour" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="General health concerns" onClick={motivationHandler} />
                                            <span>{t("general_health_concerns")}</span>
                                        </label>
                                        <label>      
                                            <input id="motivationfive" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Sudden event" onClick={motivationHandler} />
                                            <span>{t("sudden_event")}</span>
                                        </label>
                                        <label>
                                            <input id="motivationsix" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Ongoing health issues or risk" onClick={motivationHandler} />
                                            <span>{t("ongoing_health_issues_or_risk")}</span>
                                        </label>
                                        <label>
                                            <input id="motivationsev" type="checkbox" className="motivation_sleep" name="motivation_for_improving_sleep" value="Other" onClick={motivationHandler} />
                                            <span>{t("other_risk")}</span>
                                        </label>
                                    </div>
                                </div>
                                <button id="motivation__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="15_motivation">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>16</div>
                            <form className="card card-main card-default multiple-choice flex-30">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={Partner} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content">
                                    <h4 id="card_title-17" className="card__title">{t("discussed_sleep_related_issues_with_any_of_these")} <span>{t("choice_notice")}</span></h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <label>
                                            <input id="discussed" type="checkbox" className="discussed_sleep" name="discussed_sleep_related_issues_with_any_of_these" value="Doctor" onClick={discussedHandler}/>
                                            <span>{t("doctor")}</span>
                                        </label>    
                                        <label>
                                            <input id="discussedone" type="checkbox" className="discussed_sleep" name="discussed_sleep_related_issues_with_any_of_these" value="Partner" onClick={discussedHandler}/>
                                            <span>{t("partner")}</span>
                                        </label>
                                        <label>
                                            <input id="discussedtwo" type="checkbox" className="discussed_sleep" name="discussed_sleep_related_issues_with_any_of_these" value="Friend" onClick={discussedHandler}/>
                                            <span>{t("friend")}</span>
                                        </label>
                                        <label>
                                            <input id="discussedthree" type="checkbox" className="discussed_sleep" name="discussed_sleep_related_issues_with_any_of_these" value="Relative" onClick={discussedHandler}/>
                                            <span>{t("relative")}</span>
                                        </label>
                                        <label>
                                            <input id="discussedfour" type="checkbox" className="discussed_sleep" name="discussed_sleep_related_issues_with_any_of_these" value="None" onClick={discussedHandler}/>
                                            <span>{t("none")}</span>
                                        </label>
                                    </div>
                                </div>
                                <button id="discussed__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="16_issue_discussed">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>17</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={SleepExpression} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content describe_your_sleep">
                                    <h4 id="card_title-17" className="card__title">{t("how_would_you_describe_your_sleep")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Terrible" onClick={describeHandler}>
                                            {t("terrible")}
                                        </button>
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Disturbed" onClick={describeHandler}>
                                            {t("disturbed")}
                                        </button>
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Could be better" onClick={describeHandler}>
                                            {t("could_be_better")}
                                        </button>
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Okay" onClick={describeHandler}>
                                            {t("light")}
                                        </button>
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Satisfied" onClick={describeHandler}>
                                            {t("deep")}
                                        </button>
                                        <button id="describe" type="button" role="radio" aria-checked="false" name="how_would_you_describe_your_sleep" className="btn-primary flex-4 btn " value="Great" onClick={describeHandler}>
                                            {t("great")}
                                        </button>
                                    </div>
                                </div>
                                <button id="describe__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="17_describe_your_sleep">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>18</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={clock} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_sleep_per_night">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_hours_of_sleep_per_night")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="average" type="button" role="radio" aria-checked="false" name="insomnia_hours_of_sleep_per_night" className="btn-primary flex-4 btn " value="Less than 5 hours" onClick={averageHandler}>
                                            {t("less_than_5_hours")}
                                        </button>
                                        <button id="average" type="button" role="radio" aria-checked="false" name="insomnia_hours_of_sleep_per_night" className="btn-primary flex-4 btn " value="5 to 7 hours" onClick={averageHandler}>
                                            {t("five_six_hours")}
                                        </button>
                                        <button id="average" type="button" role="radio" aria-checked="false" name="insomnia_hours_of_sleep_per_night" className="btn-primary flex-4 btn " value="7 to 10 hours" onClick={averageHandler}>
                                            {t("seven_nine_hours")}
                                        </button>
                                        <button id="average" type="button" role="radio" aria-checked="false" name="insomnia_hours_of_sleep_per_night" className="btn-primary flex-4 btn " value="More than 10 hours" onClick={averageHandler}>
                                            {t("more_than_10_hours")}
                                        </button>
                                    </div>
                                </div>
                                <button id="average__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="18_hours_of_sleep">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>19</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={a_pain} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_pain_trouble">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_pain_related_sleep_trouble")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="sleeping" type="button" role="radio" aria-checked="false" name="insomnia_pain_related_sleep_trouble" className="btn-primary flex-4 btn " value="Never" onClick={sleepingHandler}>
                                            {t("never")}
                                        </button>
                                        <button id="sleeping" type="button" role="radio" aria-checked="false" name="insomnia_pain_related_sleep_trouble" className="btn-primary flex-4 btn " value="Less than once a week" onClick={sleepingHandler}>
                                            {t("less_than_once_week")}
                                        </button>
                                        <button id="sleeping" type="button" role="radio" aria-checked="false" name="insomnia_pain_related_sleep_trouble" className="btn-primary flex-4 btn " value="Once or twice a week" onClick={sleepingHandler}>
                                            {t("once_Twice_week")}
                                        </button>
                                        <button id="sleeping" type="button" role="radio" aria-checked="false" name="insomnia_pain_related_sleep_trouble" className="btn-primary flex-4 btn " value="Three or more times a week" onClick={sleepingHandler}>
                                            {t("Three_more_times_week")}
                                        </button>
                                    </div>
                                </div>
                                <button id="sleeping__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="19_trouble_sleeping">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>20</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={cantsleep} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_falling_asleep">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_difficulty_falling_asleep")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="severe" type="button" role="radio" aria-checked="false" name="insomnia_difficulty_falling_asleep" className="btn-primary flex-4 btn " value="No problem" onClick={severeHandler}>
                                            {t("no_problem")}
                                        </button>
                                        <button id="severe" type="button" role="radio" aria-checked="false" name="insomnia_difficulty_falling_asleep" className="btn-primary flex-4 btn " value="Few issues" onClick={severeHandler}>
                                            {t("few_issues")}
                                        </button>
                                        <button id="severe" type="button" role="radio" aria-checked="false" name="insomnia_difficulty_falling_asleep" className="btn-primary flex-4 btn " value="Moderate" onClick={severeHandler}>
                                            {t("moderate")}
                                        </button>
                                        <button id="severe" type="button" role="radio" aria-checked="false" name="insomnia_difficulty_falling_asleep" className="btn-primary flex-4 btn " value="Severe" onClick={severeHandler}>
                                            {t("severe")}
                                        </button>
                                        <button id="severe" type="button" role="radio" aria-checked="false" name="insomnia_difficulty_falling_asleep" className="btn-primary flex-4 btn " value="Very severe" onClick={severeHandler}>
                                            {t("very_severe")}
                                        </button>
                                    </div>
                                </div>
                                <button id="severe__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="20_difficult_falling_sleep">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>                     
                        <div className="card-wrapper">
                            <div className='countNum'>21</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={sun} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_waking">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_waking_up_too_early")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="waking" type="button" role="radio" aria-checked="false" name="insomnia_waking_up_too_early" className="btn-primary flex-4 btn " value="No problem" onClick={wakingHandler}>
                                            {t("no_problem")}
                                        </button>
                                        <button id="waking" type="button" role="radio" aria-checked="false" name="insomnia_waking_up_too_early" className="btn-primary flex-4 btn " value="Few issues" onClick={wakingHandler}>
                                            {t("few_issues")}
                                        </button>
                                        <button id="waking" type="button" role="radio" aria-checked="false" name="insomnia_waking_up_too_early" className="btn-primary flex-4 btn " value="Moderate" onClick={wakingHandler}>
                                            {t("moderate")}
                                        </button>
                                        <button id="waking" type="button" role="radio" aria-checked="false" name="insomnia_waking_up_too_early" className="btn-primary flex-4 btn " value="Severe" onClick={wakingHandler}>
                                            {t("severe")}
                                        </button>
                                        <button id="waking" type="button" role="radio" aria-checked="false" name="insomnia_waking_up_too_early" className="btn-primary flex-4 btn " value="Very severe" onClick={wakingHandler}>
                                            {t("very_severe")}
                                        </button>
                                    </div>
                                </div>
                                <button id="waking__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="21_issue_wake_up">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>22</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={sleepy} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_sleep_negatively">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_sleep_negatively_impacting_quality_of_life")}</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="extend" type="button" role="radio" aria-checked="false" name="insomnia_sleep_negatively_impacting_quality_of_life" className="btn-primary flex-4 btn " value="None" onClick={extendHandler}>
                                            {t("none")}
                                        </button>
                                        <button id="extend" type="button" role="radio" aria-checked="false" name="insomnia_sleep_negatively_impacting_quality_of_life" className="btn-primary flex-4 btn " value="A little" onClick={extendHandler}>
                                            {t("a_little")}
                                        </button>
                                        <button id="extend" type="button" role="radio" aria-checked="false" name="insomnia_sleep_negatively_impacting_quality_of_life" className="btn-primary flex-4 btn " value="Somewhat" onClick={extendHandler}>
                                            {t("somewhat")}
                                        </button>
                                        <button id="extend" type="button" role="radio" aria-checked="false" name="insomnia_sleep_negatively_impacting_quality_of_life" className="btn-primary flex-4 btn " value="Moderately" onClick={extendHandler}>
                                            {t("moderately")}
                                        </button>
                                        <button id="extend" type="button" role="radio" aria-checked="false" name="insomnia_sleep_negatively_impacting_quality_of_life" className="btn-primary flex-4 btn " value="A lot" onClick={extendHandler}>
                                            {t("a_lot")}
                                        </button>
                                    </div>
                                </div>
                                <button id="extend__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={nextHandler} data-event-label="22_quality_of_life">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        <div className="card-wrapper">
                            <div className='countNum'>23</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={anxious} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="card__inner__content insomnia_worried_sleep">
                                    <h4 id="card_title-17" className="card__title">{t("insomnia_worried_about_your_sleep")}?</h4>
                                    <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                        <button id="worried" type="button" role="radio" aria-checked="false" name="insomnia_worried_about_your_sleep" className="btn-primary flex-4 btn " value="Not at all" onClick={worriedHandler}>
                                            {t("noone")}
                                        </button>
                                        <button id="worried" type="button" role="radio" aria-checked="false" name="insomnia_worried_about_your_sleep" className="btn-primary flex-4 btn " value="A little" onClick={worriedHandler}>
                                            {t("a_little")}
                                        </button>
                                        <button id="worried" type="button" role="radio" aria-checked="false" name="insomnia_worried_about_your_sleep" className="btn-primary flex-4 btn " value="Somewhat" onClick={worriedHandler}>
                                            {t("somewhat")}
                                        </button>
                                        <button id="worried" type="button" role="radio" aria-checked="false" name="insomnia_worried_about_your_sleep" className="btn-primary flex-4 btn " value="Moderately" onClick={worriedHandler}>
                                            {t("moderately")}
                                        </button>
                                        <button id="worried" type="button" role="radio" aria-checked="false" name="insomnia_worried_about_your_sleep" className="btn-primary flex-4 btn " value="A lot" onClick={worriedHandler}>
                                            {t("a_lot")}
                                        </button>
                                    </div>
                                </div>
                                <button id="worried__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={redirectHandler} data-event-label="23_current_sleep_worry">
                                    {t("next")}
                                </button>
                                <button id="prev-btn" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>
                        {/* <div className="card-wrapper">
                            <div className='countNum'>24</div>
                            <form className="card card-main card-default">
                                <div className="u-position-absolute card__animation" >
                                <div title="" role="" aria-label="">
                                    <img src={anxious} />
                                </div>
                                </div>
                                <div aria-hidden="true" className="bg-circle__wrapper">
                                    <div className="bg-circle"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__left">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div aria-hidden="true" className="card__stack card__stack__right ">
                                    <div className="card card-default"></div>
                                    <div className="card card-default"></div>
                                </div>
                                <div className="discussOuter">
                                    <div className="card__inner__content">
                                        <div className="representative_discuss" id="representative_discuss">
                                            <h4 id="card_title-17" className="card__title">Would you like a call from a ResMed representative to discuss options to improve your sleep?</h4>
                                            <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                                <button id="representative" type="button" role="radio" aria-checked="false" name="representative_discuss" className="btn-primary flex-4 btn " value="Yes">
                                                    {t("yes")}
                                                </button>
                                                <button id="representative" type="button" role="radio" aria-checked="false" name="representative_discuss" className="btn-primary flex-4 btn " value="No">
                                                    {t("no")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="phoneOuterCol" id="phoneOuterCol">
                                            <h4 id="card_title-17" className="card__title emptyNum">Please leave your contact number for us to reach out to you</h4>
                                            <h4 id="card_title-17" className="card__title existNum">Please confirm your contact number for us to reach to you</h4>
                                            <div role="radiogroup" aria-labelledby="card_title-17" className="btn-group">
                                                <input type="text" id="phonenumber" name="phonenumber" maxLength={8} placeholder="Phone Number" onChange={phoneHandler}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button id="representative__next" type="button" className="next-btn btn gtm-click" disabled="" value="" onClick={redirectHandler} data-event-label="24_sleep_coach_call">
                                    {t("next")}
                                </button>
                                <button id="prevbtnNew" type="button" className="btn prev-btn" disabled="" value="" >{t("prev")}</button>
                                <button id="prev-btnLast" type="button" className="prev-btn btn " disabled="" value="" onClick={prevHandler}>{t("prev")}</button>
                            </form>
                        </div>                     */}
                    </Slider>
                    <div className="slide__counter">
                        <div className="slider__count"><span className="count">1</span><span className="count__total">/</span></div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        
        
    );
}

export default Quiz;