import React, { useState } from 'react';
import './Newform_section.css';
import './BodyForm.css';
import Icon from "react-native-vector-icons/FontAwesome5";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import formbackground_image from '../../images/background-community.jpg';
import loadIcon from '../../images/loading_icon.gif';
import LearnMorePopup from './Learnmore_popup';
import author_image from '../../images/author_image.png';
// import { REACT_APP_SECRET_PHRASE } from 'react-native-dotenv';
import { REACT_APP_PHRASE, REACT_APP_API, REACT_APP_API_URL } from "@env"
import CryptoJS from 'crypto-js';
// import gtag, { install } from 'ga-gtag';

// install('GTM-W2HVGBF');

const formbox = {
    display: "none",
    backgroundImage: `url(${loadIcon})`
}

// let url = window.location.href;
// let ref, getUrlParameter;
// const queryParams = new URLSearchParams(window.location.search);
// const referenceVal = queryParams.get('utm_source');

export default function Newformsec() {
    const [enteredFirstName, setEnteredFirstName] = useState("");
    const [enteredLastName, setEnteredLastName] = useState("");
    const [enteredEmail, setEnteredEmail] = useState("");
    const [enteredPerEmail, setEnteredPerEmail] = useState("");
    const [enteredPhone, setEnteredPhone] = useState("");

    $(document).ready(function() {
        var s = $(".top_block_section");
        var pos = s.position();
        $(window).scroll(function() {
            var windowpos = $(window).scrollTop();
            if (windowpos >= pos.top - 700 & windowpos <= 1000) {
                s.addClass("active");
            } else {
                s.removeClass("active");
            }
        });
    });

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validateEmailPer(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function nameHandler(event) {
        const name_val = event.target.value;
        setEnteredFirstName(event.target.value);
        localStorage.setItem('myData', name_val);
    }

    function lastnameHandler(event) {
        const last_name = event.target.value;
        setEnteredLastName(event.target.value);
        localStorage.setItem('lastName', last_name);
    }

    function phoneHandler(event) {
        const phone_number = event.target.value;
        setEnteredPhone(event.target.value);
        localStorage.setItem('phoneNumber', phone_number);
    }

    // var acceptList = [ "resmed.com.au" , "resmed.com.sg" , "resmed.co.in" , "resmed.kr", "resmed.com" ];
    // function emailHandler(event) {
    //     const email_val = event.target.value;
    //     setEnteredEmail(event.target.value);
    //     if (validateEmail(email_val)) {
    //         document.getElementById('email_valid').style.display = 'none';
    //     } else {
    //         document.getElementById('email_valid').style.display = 'block';
    //     }

    //     var emailValue = email_val;
    //     var splitArray = emailValue.split('@');

    //     if(acceptList.indexOf(splitArray[1]) >= 0) {
    //         document.getElementById('email_valid').style.display = 'none';
    //     } else {
    //         document.getElementById('email_valid').style.display = 'block';
    //     }
    // }

    function peremailHandler(event) {
        const per_email_val = event.target.value;
        setEnteredPerEmail(event.target.value);
        if (validateEmailPer(per_email_val)) {
            document.getElementById('email_valid_per').style.display = 'none';
        } else {
            document.getElementById('email_valid_per').style.display = 'block';
        }
        localStorage.setItem('emailVal', per_email_val);
    }

    let history = useHistory();
    var ln_status;
    var fn_status;
    var em_status;
    var checkbox_status;

    const redirect = (event) => {
        event.preventDefault();
        ln_status = true;
        fn_status = true;
        em_status = true;
        checkbox_status = true;

        if (enteredLastName.trim().length === 0) {
            ln_status = false;
            document.getElementById('lastname_error').style.display = 'block';
            document.getElementById("lastName").parentElement.classList.add("input__error");
        } else {
            ln_status = true;
            document.getElementById('lastname_error').style.display = 'none';
            document.getElementById("lastName").parentElement.classList.remove("input__error");
        }

        if (enteredFirstName.trim().length === 0) {
            fn_status = false;
            document.getElementById('firstname_error').style.display = 'block';
            document.getElementById("firstName").parentElement.classList.add("input__error");
        } else {
            fn_status = true;
            document.getElementById('firstname_error').style.display = 'none';
            document.getElementById("firstName").parentElement.classList.remove("input__error");
        }

        // if(referenceVal) {
        //     if (enteredEmail.trim().length === 0) {
        //         em_status = false;
        //         document.getElementById('email_error').style.display = 'block';
        //         document.getElementById("emailIp").parentElement.classList.add("input__error");
        //     } else {
        //         em_status = true;
        //         document.getElementById('email_error').style.display = 'none';
        //         document.getElementById("emailIp").parentElement.classList.remove("input__error");
        //     }
        // }

        var chkPassport = document.getElementById("agree");
        if (chkPassport.checked) {
            checkbox_status = true;
            document.getElementById('req_error').style.display = 'none';
            // var checkError = document.getElementById("form_check");
            // checkError.classList.remove("error__check");
        } else {
            checkbox_status = true;
            document.getElementById('req_error').style.display = 'none';
            // var checkError = document.getElementById("form_check");
            // checkError.classList.add("error__check");
        }

        const cb = document.getElementById('agree');
        const agreeStatus = cb.checked;

        // const cm = document.getElementById('marketing_communications');
        // const marketStatus = cm.checked;

        if (ln_status == true && fn_status == true && em_status == true && checkbox_status == true) {
  
            var encrypted = CryptoJS.AES.encrypt(JSON.stringify({
                email: enteredPerEmail,
                first_name: enteredFirstName,
                last_name: enteredLastName,
                mobilephone: enteredPhone,
                //lead_source: referenceVal,
                staff_email: enteredEmail,
                sleep_assessment_terms_and_conditions: agreeStatus,
                marketing_communications: "true",
                pageURL: "https://sleepquiz.resmed.sg",
                pageName: "ResMed Singapore Sleep Quiz"
            }), REACT_APP_PHRASE);
            
            fetch(REACT_APP_API_URL, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': REACT_APP_API
                },  
                body: encrypted
            }).then(function(response) {
                return response.json();
            }).then(function(data) {
                console.log('Created Gist:', data);
                //console.log(enteredPerEmail, enteredEmail);
                window.location.href = '/quiz';
            });
            document.body.classList.add("active__loader");
            setTimeout(function() {
                setEnteredFirstName("");
                setEnteredLastName("");
                setEnteredEmail("");
                setEnteredPhone("");
            }, 1000);
        }
    }
    const { t } = useTranslation();
    return ( 
        <div className="form_section_outer_wrp"  style={{ backgroundImage: `url(${formbackground_image})`}}>
            <div id="loading__icon" style={formbox}>
            </div>
            <div className="container">
                <div className="top_block_section">
                    <div className="block_left_section">
                        <h2>{t("block_left_header")}</h2> 
                        <p>{t("block_left_content")} <sup>1</sup></p>
                    </div>
                    <div className="block_right_section">
                        <h3>{t("block-right_header")}</h3> 
                        <p>{t("block-right-content")}</p> 
                    </div > 
                </div>  
                <div className="form_wrapper">
                    <div className="form_left_header">
                        <h2>{t("newform_header")}</h2> 
                        <p>{t("newform_subheader")}</p> 
                    </div> 
                    <div className="form_right_section">
                        <form>
                            <div className='form__controls'>
                                <div className='form__control form__control__flex'>
                                    <div className='form__control__inner'>
                                        <label>{t("lastname")}</label> 
                                        <input type='text' name="last_name" value={enteredLastName} onChange={lastnameHandler} id="lastName"/> 
                                        <div className="lastname_error" id="lastname_error" style={{ display: 'none' }}> Please complete this required field </div> 
                                    </div> 
                                    <div className='form__control__inner'>
                                        <label>{t("firstname")}</label> 
                                        <input type='text' name="first_name" value={enteredFirstName} onChange={nameHandler} id="firstName"/> 
                                        <div className="firstname_error" id="firstname_error" style={{ display: 'none' }}> Please complete this required field </div> 
                                    </div> 
                                </div> 
                                {/* {referenceVal == "s4ahl" && <div className='form__control'>
                                    <label>{t("email")}*</label>
                                    <input type='email' name="email" value={enteredEmail} onChange={emailHandler} id="emailIp"/> 
                                    <div id="email_valid" className="email_valid" style={{ display: 'none' }}> Please enter valid email address </div> 
                                    <div className="email_error" id="email_error" style={{ display: 'none' }} > Please complete this required field </div> 
                                </div> } */}
                                <div className='form__control'>
                                    <label>{t("per_email")}*</label>
                                    <input type='email' name="email" value={enteredPerEmail} onChange={peremailHandler} id="emailIpper"/> 
                                    <div id="email_valid_per" className="email_valid_per" style={{ display: 'none' }}> Please enter valid email address </div> 
                                </div>
                                <div className='form__control' id="phNo">
                                    <label>{t("phone")}</label> 
                                    <input type='tel' name="mobilephone" value={ enteredPhone } onChange={ phoneHandler }/> 
                                </div> 
                                <div className='form__control form__check' id='form_check'>
                                    <label>
                                        <input id="agree" name = "sleep_assessment_terms_and_conditions" className = "terms__policy" type = "checkbox" />
                                        <span> {t("quiz_agree_text")} <a id="learnPop">{t("learn_more")}</a> </span>  
                                    </label>
                                    <div className='checkboxerror'>Please complete this required field</div>
                                </div> 
                                <div className='form__control form__market'>
                                    <label >
                                            {/* <input id = "marketing_communications" name = "marketing_communications" className = "terms__policy" type = "checkbox" /> */}
                                        <span> {t("quiz_agree_text_sec")} <a href="https://www.resmed.sg/terms-of-use" target="_blank">{t("terms")}</a> {t("quiz_agree_text_sec_para")} <a href="https://www.resmed.sg/privacy-notice" target="_blank">{t("policy")}</a>.</span>  
                                    </label >
                                </div> 
                            </div > 
                            <div className="req_error" id="req_error" style={{display: 'none'}}> Please fill required field </div> 
                            <div className='form__actions'>
                                <button type='submit' onClick={ redirect } data-event-label="sleep quiz personal details" className='gtm-click'> <span id="formBtn">{t("formbtntext")}</span>
                                    <Icon name="arrow-right" size={ 12 } color="#fff" /> 
                                </button> 
                            </div > 
                        </form> 
                    </div>
                </div>  
                <div className="form_reference_section">
                    <div className="form_reference_header">
                        <h2>{t("newFormrefHeader")}</h2> 
                    </div > 
                    <div className="form_reference_contenet">
                        <p><span> 1 </span>{t("newFormContent")}</p>
                    </div> 
                </div>
            </div>
            <LearnMorePopup/>
        </div>
    );

}