import React from 'react';
import "./Learnmore_popup.css";
import $ from 'jquery';
import { useTranslation } from "react-i18next";

export default function LearnMorePopup() {

    $(document).ready(function() {
        document.getElementById('learnPop').onclick = function() {
            document.getElementById('learnpopOuterWrapper').style.display = 'block'
        };

        document.getElementById('learnmorepopClose').onclick = function() {
          document.getElementById('learnpopOuterWrapper').style.display = 'none'
        };
    });
    const { t } = useTranslation();
    return (
        <div className="learnpopOuterWrapper" id="learnpopOuterWrapper">
            <div className="learnpopInnerWrapper">
                <div className="learnmorepopClose" id='learnmorepopClose'>x</div>
                <p>{t("learmore_pop_textone")} <a href="https://www.resmed.sg/privacy-notice" target="_blank">{t("policy")}</a>. {t("learmore_pop_text")} <a href="https://www.resmed.sg/privacy-notice" target="_blank">{t("policy")}</a>.</p>
            </div>
        </div>
    );
}